import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration

// Initialize Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyBmqNcgfIBvndg9KBf1KEpW6NhYAvg0iK8',
  authDomain: 'hub-kuti-io-91549.firebaseapp.com',
  databaseURL: 'https://hub-kuti-io-91549.firebaseio.com',
  projectId: 'hub-kuti-io-91549',
  storageBucket: 'hub-kuti-io-91549.appspot.com',
  messagingSenderId: '497504382449',
  appId: '1:497504382449:web:95f7641e54e1f3e1e92850',
  measurementId: 'G-1FFKTNES7H',
};

const firebase = initializeApp(firebaseConfig);
const storage = getStorage(firebase);
const firestore = getFirestore(firebase);
const database = getDatabase(firebase);
const auth = getAuth(firebase);

export { storage, firestore, database, firebase as default, auth };
