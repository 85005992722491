import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Constants } from '../../config';
import { useDispatch } from 'react-redux';
import { fetchUserProfile } from '../../actions/user';

const AuthCallback = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    const token = params.get(Constants.ACCESS_TOKEN_QUERY_PARAM);
    if (token) {
      localStorage.setItem(Constants.REFRESH_TOKEN_KEY, token);
      dispatch(fetchUserProfile(token));
    }
    navigate('/');
  }, []);

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <h2>Authenticating...</h2>
    </div>
  );
};

export default AuthCallback;
