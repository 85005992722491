import React, { Component } from 'react';
import { connect } from 'react-redux';
import { paymentMethods } from '../../actions';

class PaymentMethodsList extends Component {
  checkBrandCard = (brand) => {
    if (brand === 'Visa') {
      return 'fa fa-cc-visa fa-2x';
    } else if (brand === 'MasterCard') {
      return 'fa fa-cc-mastercard fa-2x';
    } else if (brand === 'American Express') {
      return 'fa fa-cc-amex fa-2x';
    } else if (brand === 'Discover') {
      return 'fa fa-cc-discover fa-2x';
    } else if (brand === 'JCB') {
      return 'fa fa-cc-jsb fa-2x';
    } else if (brand === 'Diners Club') {
      return 'fa fa-cc-diners-club fa-2x';
    } else {
      return 'fa fa-credit-card fa-2x';
    }
  };

  setDefaultPayment = async () => {
    await this.props.setDefaultPaymentMethod(
      // this.props.currentUser.uid,
      // this.props.currentUser.accessToken,
      this.props.data
    );
  };

  removePaymentMethod = async () => {
    await this.props.detachPaymentMethod(
      // this.props.currentUser.uid,
      // this.props.currentUser.accessToken,
      this.props.data
    );
  };

  render() {
    const { data } = this.props;
    return (
      <div className="shadow col-lg-12 border mg-bottom-10 float-left">
        <div className="col-lg-12 mgTopBottom card-payment">
          <div className={this.checkBrandCard(data.card.brand)} />
          <h5 className="payment-card">{data.card.brand}</h5>
          <h5 className="payment-card">xxxx-xxxx-xxxx-{data.card.last4}</h5>
          <h5 className="payment-card">
            {data.card.exp_month}/{data.card.exp_year}
          </h5>
          {data.default ? (
            <button className="pull-right btn btn-payment-card-active">
              Active
            </button>
          ) : (
            <div className="pull-right">
              <button
                type="button"
                className="btn btn-payment-card-remove pull-right"
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  this.removePaymentMethod();
                }}
              >
                X
              </button>
              <button
                type="button"
                className="pull-right btn btn-payment-card-setDefault"
                onClick={() => {
                  this.setDefaultPayment();
                }}
              >
                Set Default
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  shoppingCartData: state.shoppingCart.data,
  ...ownProps,
});

const mapActionsToProps = {
  setDefaultPaymentMethod: paymentMethods.setDefaultPaymentMethod,
  detachPaymentMethod: paymentMethods.detachPaymentMethod,
};

export default connect(mapStateToProps, mapActionsToProps)(PaymentMethodsList);
