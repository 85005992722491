import React, { Component } from 'react';
import {
  UploadPlugin,
  EditPlugin,
  DeletePlugin,
  ListView,
  CardView,
  SkeletonComp,
} from '../../components';
import { connect } from 'react-redux';
import { fetchMyPlugins, changeMyPluginState } from '../../actions/myPlugins';
import { oss, details } from '../../actions';
import { resetDetailsData } from '../../actions/details';
import { setActiveViewType } from '../../actions/activeState';
import { orderBy } from 'lodash';
import {
  extractArtifactJsonInfo,
  stripPluginSuffixPrefix,
} from '../../helpers';
import { withRouter } from '../../hooks';

class MyPlugins extends Component {
  uploadPluginHandler = () => {
    this.props.changeMyPluginState('uploadPlugins');
  };

  goToDetails = (selectedArtifact, selectedWidget) => {
    const allArtifactVersions = orderBy(
      this.props.myPlugins.filter(
        (artifact) => artifact.name === selectedArtifact.name
      ),
      'version',
      'desc'
    ).map((artifactVersion) => ({
      ...artifactVersion,
      activeTab: 'plugins',
      activeSubTab: 'cli',
      jsonFileContent: {
        parents: [],
      },
      jsonInfo: extractArtifactJsonInfo(artifactVersion),
      hasDocumentation:
        artifactVersion.docs_file &&
        artifactVersion.docs_file.length === 1 &&
        artifactVersion.docs_file[0].link.startsWith('http'),
    }));
    const artifactIndex = allArtifactVersions.findIndex(
      (artifact) => artifact.version === selectedArtifact.version
    );
    const widgetName =
      selectedWidget && selectedWidget.name ? selectedWidget.name : null;
    const widgetIndex =
      selectedWidget !== null
        ? selectedArtifact.widgets.findIndex(
            (widget) => widget.name === selectedWidget.name
          )
        : -1;
    const widgetStrippedName =
      widgetIndex !== -1
        ? stripPluginSuffixPrefix(selectedWidget.name).toLowerCase()
        : null;
    this.props.setDetailsArtifact(
      allArtifactVersions,
      artifactIndex,
      widgetIndex,
      widgetName
    );
    this.props.history.push({
      pathname:
        widgetIndex === -1
          ? `/details/${selectedArtifact.name}/${selectedArtifact.version}/`
          : `/details/${selectedArtifact.name}/${selectedArtifact.version}/${widgetStrippedName}/`,
    });
  };

  calculateItemsToRender = () => {
    let itemsToRender = [];
    this.props.data.forEach((artifactItem, index) => {
      const artifactIsSingleton =
        !artifactItem.widgets || artifactItem.widgets.length === 1;
      const artifactIsBundle = !artifactIsSingleton;
      const renderArtifact =
        artifactIsSingleton ||
        this.props.filters.showBundles === artifactIsBundle;
      const renderWidgets =
        artifactItem.widgets && artifactItem.widgets.length > 1;
      if (renderArtifact) {
        itemsToRender.push({
          type: 'artifact',
          artifact: artifactItem,
          widget: null,
        });
      }
      if (renderWidgets) {
        artifactItem.widgets.forEach((artifactItemWidget, widgetIndex) => {
          itemsToRender.push({
            type: 'widget',
            artifact: artifactItem,
            widget: artifactItemWidget,
          });
        });
      }
    });
    return itemsToRender;
  };

  componentDidMount() {
    this.props.fetchMyPlugins();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      const itemsToRender = this.calculateItemsToRender();
      this.props.setItemsCount(itemsToRender.length);
    }
  }

  render() {
    const { activeViewType } = this.props;
    if (this.props.isLoading) {
      // return <LoadingSpinner textToShow="Loading..." />;
      return <SkeletonComp />;
    }
    const itemsToRender = this.calculateItemsToRender();
    return (
      <div className="col-lg-12">
        <div className="row">
          {this.props.activeComponent === 'myPlugins' && (
            <div className="container myPlugins" style={{ width: '100%' }}>
              <div className="header-body">
                <div className="row text-right">
                  <div className="col-xl-12 col-lg-12">
                    <button
                      className="btn btn-primary pull-right btn-md"
                      onClick={() => this.uploadPluginHandler()}
                    >
                      Upload Plugin
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12 col-lg-12">
                    <div className="card-stats mb-4 mb-xl-0 pd-10 bg-white col-lg-12">
                      {activeViewType === 'card' ? (
                        <div className="row">
                          <CardView
                            data={itemsToRender}
                            filters={this.props.filters}
                            goToDetails={(artifact, widget) =>
                              this.goToDetails(artifact, widget)
                            }
                          />
                        </div>
                      ) : (
                        <div className="row">
                          <ListView
                            data={itemsToRender}
                            filters={this.props.filters}
                            goToDetails={(artifact, widget) =>
                              this.goToDetails(artifact, widget)
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.props.activeComponent === 'uploadPlugins' && <UploadPlugin />}
          {this.props.editPluginPopup && <EditPlugin />}
          {this.props.deletePluginPopup && <DeletePlugin />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // isLoading: state.myPlugins.isLoading,
  isLoading: state.oss.isLoading,
  data: state.oss.myPluginsFilteredData,
  // data: state.myPlugins.data,
  currentUser: state.user.currentUser,
  activeViewType: state.activeState.activeViewType,
  activeComponent: state.myPlugins.activeComponent,
  itemsCount: state.oss.itemsCount,
  filters: state.oss.filters,
  allPlugins: state.oss.data,
  myPlugins: state.myPlugins.data,
  editPluginPopup: state.myPlugins.editPluginPopup,
  deletePluginPopup: state.myPlugins.deletePluginPopup,
});

const mapActionToProps = {
  fetchMyPlugins,
  changeMyPluginState,
  setActiveViewType,
  resetDetailsData,
  setItemsCount: oss.setItemsCount,
  setDetailsArtifact: details.setDetailsArtifact,
};

export default withRouter(
  connect(mapStateToProps, mapActionToProps)(MyPlugins)
);
