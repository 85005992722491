import { find, get, get as getByKey, isEmpty, filter, sortBy, uniqBy, orderBy } from 'lodash';
import Fuse from 'fuse.js';

const pluginTypes = [
  '-action',
  '-batchsource',
  '-batchsink',
  '-splittertransform',
  '-streamingsource',
  '-transform',
  '-postaction',
  '-sparksink',
  '-sparkcompute',
  '-sparkprogram',
  '-batchaggregator',
  '-batchjoiner',
  '-alertpublisher',
  '-windower',
  '.json',
  '.png',
  '.md',
  '.jar',
];

const extensions = ['.json', '.md', '.jar', '.png'];

export const stripPluginSuffixPrefix = (plugName) => {
  let newPluginName = plugName;
  pluginTypes.forEach((suffixPrefix) => {
    newPluginName = newPluginName.replace(suffixPrefix, '');
  });
  return newPluginName;
};

export const stripExtension = (name) => {
  let newName = name;
  extensions.forEach((extension) => {
    newName = newName.replace(extension, '');
  });
  return newName;
};

export const mapWidgetNamesToWigetRoot = (widgets) => {
  return widgets.map((widget) => ({
    ...widget,
    widgetName:
      getByKey(widget, 'data.configuration-groups[0].label', null) ||
      widget.name,
  }));
};

export const extractArtifactJsonInfo = (artifact) => {
  const artifactArguments = get(artifact, 'spec_json.actions[0].arguments', []);

  const findArtifactJsonFileName = find(artifactArguments, {
    name: 'config',
  });
  const artifactJsonFileName = !isEmpty(findArtifactJsonFileName)
    ? findArtifactJsonFileName.value
    : null;

  const findArtifactName = find(artifactArguments, { name: 'name' });
  const artifactName = !isEmpty(findArtifactName)
    ? findArtifactName.value
    : null;

  const findArtifactJarFileName = find(artifactArguments, { name: 'jar' });
  const artifactJarFileName = !isEmpty(findArtifactJarFileName)
    ? findArtifactJarFileName.value
    : null;

  return {
    artifactJsonFileName,
    artifactName,
    artifactJarFileName,
  };
};

function fitlerArtifactsByVersion(data, version) {
  if (version === 'all') {
    return data;
  }
  return data.filter(function(element) {
    if (element.converted_cdapVersion) {
      const cdapFrom = parseFloat(element.converted_cdapVersion.from);
      const cdapTo = parseFloat(element.converted_cdapVersion.to);
      const versionToCompare = parseFloat(version);
      if (
        element.converted_cdapVersion.includeFrom === true &&
        element.converted_cdapVersion.includeTo === true
      ) {
        return versionToCompare >= cdapFrom && versionToCompare <= cdapTo;
      } else if (
        element.converted_cdapVersion.includeFrom === true &&
        element.converted_cdapVersion.includeTo === false
      ) {
        return versionToCompare >= cdapFrom && versionToCompare < cdapTo;
      }
    }
    return false;
  });
}

function fitlerArtifactsByType(data, pluginType) {
  if (pluginType !== 'all') {
    const result = [];
    const dataFilter = [...data.filter((element) => element.widgets)];
    dataFilter.forEach((artifact) => {
      const widgetPluginTypeFound = artifact.widgets.findIndex(
        (widget) => widget.pluginType === pluginType
      );
      let tempArtifact = {
        ...artifact,
      };
      if (widgetPluginTypeFound !== -1) {
        tempArtifact['widgets'] = filter(artifact.widgets, {
          pluginType: pluginType,
        });
        result.push(tempArtifact);
      }
    });
    return result;
  } else {
    return data;
  }
}

function getArtifactsUniqueByName(data) {
  return sortBy(
    uniqBy(orderBy(data, ['name', 'version'], ['asc', 'desc']), 'name'),
    'converted_name'
  );
}

function getFilteredData(dataToFilter, filters) {
  const versionFiltered = getArtifactsUniqueByName(
    fitlerArtifactsByVersion(dataToFilter, filters.cdapVersion)
  );
  const typeFiltered = fitlerArtifactsByType(
    versionFiltered,
    filters.artifactType
  );
  return sortBy(typeFiltered, 'converted_name');
}

export function setDataToFilter(state, keyword, filters, initialOSSState) {
  let dataToFilter = '';
  let fuseSearch = '';
  if (state.activeTab === 'all' || state.activeTab === 'openSource') {
    dataToFilter =
      filters.cdapVersion === 'all'
        ? [...state.dataUniqueByName]
        : [...state.data];
    fuseSearch = new Fuse([...state.filteredData], {
      keys: initialOSSState.fuseKeys,
      ...state.fuseOptions,
    });
    return {
      ...state,
      keyword: keyword,
      filters,
      filteredData: !isEmpty(keyword)
        ? fuseSearch.search(keyword)
        : getFilteredData(dataToFilter, filters),
    };
  }
  if (state.activeTab === 'commercial') {
    dataToFilter = [...state.commercialData];
    fuseSearch = new Fuse([...state.commercialData], {
      keys: initialOSSState.fuseKeys,
      ...state.fuseOptions,
    });
    return {
      ...state,
      keyword: keyword,
      filters,
      commercialFilteredData: !isEmpty(keyword)
        ? fuseSearch.search(keyword)
        : getFilteredData(dataToFilter, filters),
    };
  }
  if (state.activeTab === 'myPlugins') {
    dataToFilter = [...state.myPlugins];
    fuseSearch = new Fuse([...state.myPlugins], {
      keys: initialOSSState.fuseKeys,
      ...state.fuseOptions,
    });
    return {
      ...state,
      keyword: keyword,
      filters,
      myPluginsFilteredData: !isEmpty(keyword)
        ? fuseSearch.search(keyword)
        : getFilteredData(dataToFilter, filters),
    };
  }
}
