import React, { Component } from 'react';
import { connect } from 'react-redux';
import { paymentMethods, shoppingCart } from '../../actions';
import { Card } from './Card';

class Plans extends Component {
  componentDidMount() {
    this.props.fetchProducts();
  }

  cancelSubscription = () => {
    this.props.cancelSubscription();
    this.props.history.push('/receipt/cancel-subscription');
  };

  // TODO fix ui and functionality
  render() {
    if (this.props.isLoadingProducts) {
      return <div style={{ paddingTop: '2.5rem' }}>Loading...</div>;
    } else {
      return (
        <>
          <div className="pricing-plans" style={{ paddingTop: '2.0rem' }}>
            <b style={{ fontSize: '20px' }}>Chose a Plan</b>
            <p>Select a plan that's right for you.</p>
            <div className="price-cards" style={{ paddingTop: '' }}>
              {this.props.products.map((product) => (
                <Card
                  priceId={product.price?.id}
                  name={product.name}
                  price={product.price?.amount}
                  features={product.meta.features}
                  currentPlan={this.props.currentPlan}
                  setPrice={this.props.setPrice}
                  currentPlanPrice={
                    this.props.products.find(
                      (product) => product.name === this.props.currentPlan
                    )?.price?.amount ?? 0
                  }
                  cancelSubscription={this.cancelSubscription}
                  image={product.image}
                />
              ))}
            </div>
          </div>
          <section className="pt-5">
            <div className="pt-4 container">
              <h1 className="text-center mb-2 py-5">Pricing Details</h1>
              <div className="pricing-table-wraper">
                <table className="pricing-table">
                  <thead className="thead">
                    <tr className="table-row">
                      <th>Features</th>
                      <th>Developer</th>
                      <th>Team</th>
                      <th>Business</th>
                      <th>Enterprise</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Unlimited OSS plugins</td>
                      <td>✓</td>
                      <td>✓</td>
                      <td>✓</td>
                      <td>✓</td>
                    </tr>
                    <tr>
                      <td>Store artifacts centrally and securely</td>
                      <td>X</td>
                      <td>✓</td>
                      <td>✓</td>
                      <td>✓</td>
                    </tr>
                    <tr>
                      <td>Limited number of private plugins</td>
                      <td>X</td>
                      <td>10</td>
                      <td>50</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td>
                        Plugins can be shared publicly and may contain closed
                        source artifacts and commercial license
                      </td>
                      <td>X</td>
                      <td>✓</td>
                      <td>✓</td>
                      <td>✓</td>
                    </tr>
                    <tr>
                      <td>
                        Limited number of CDAP sandbox instance's that can use
                        our product
                      </td>
                      <td>X</td>
                      <td>1 (single IP / dynamic)</td>
                      <td>3 IP's</td>
                      <td>Unlimited</td>
                    </tr>
                    <tr>
                      <td>
                        Pipelines Feature (Managing pipelines across multiple
                        instances. Transfer from one instance to anothe with
                        batch operations or singleton. Including plugin
                        deployment that are relevat to their pipelines. Also
                        start, delete, export pipelines in batch operations are
                        avaliable.)
                      </td>
                      <td>X</td>
                      <td>X</td>
                      <td>X</td>
                      <td>✓</td>
                    </tr>
                    <tr>
                      <td>Must include a GitHub repo</td>
                      <td>✓</td>
                      <td>X</td>
                      <td>X</td>
                      <td>X</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  currentPlan: state.user.currentPlan,
  products: state.paymentMethods.products,
  isLoadingProducts: state.paymentMethods.isLoadingProducts,
  // ...ownProps,
});

const mapActionsToProps = {
  setPrice: shoppingCart.setPrice,
  cancelSubscription: shoppingCart.cancelSubscription,
  fetchProducts: paymentMethods.fetchProducts,
};
export default connect(mapStateToProps, mapActionsToProps)(Plans);
