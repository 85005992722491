import React from 'react';
import { client } from '../../config/api';

const EmailVerification = () => {
  const [isSent, setIsSent] = React.useState(false);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#f0f4f8',
        color: '#333',
        fontFamily: 'Arial, sans-serif',
        textAlign: 'center',
        padding: '20px',
      }}
    >
      <div
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
          marginBottom: '20px',
        }}
      >
        Please verify your email
      </div>
      {isSent ? (
        <div
          style={{
            fontSize: '18px',
            marginBottom: '20px',
          }}
        >
          Check your mailbox
        </div>
      ) : (
        <button
          onClick={async () => {
            try {
              await client.post('/email/confirmation');
              setIsSent(true);
            } catch (e) {
              // handle error
            }
          }}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            outline: 'none',
            transition: 'background-color 0.3s ease',
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = '#0056b3')}
          onMouseOut={(e) => (e.target.style.backgroundColor = '#007bff')}
        >
          Resend confirmation
        </button>
      )}
    </div>
  );
};

export default EmailVerification;
