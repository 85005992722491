import './App.scss';
import React, { Component } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from './hooks';
import { Navbar, Footer, LoadingSpinner } from './components';
import {
  Details,
  Login,
  OssPlugins,
  Register,
  UserProfile,
  ShoppingCart,
  PricingPlans,
  Payment,
  Receipt,
  Pipelines,
  TransferPipelines,
  LegalAgreement,
  TermsAndConditions,
  PrivacyPolicy,
  AuthCallback,
  Credentials,
  EmailVerification,
  Logout,
} from './screens';
import { Constants } from './config';
import { pipelines } from './actions';

const publicRoutes = [
  {
    path: '/',
    component: <OssPlugins />,
  },
  {
    path: '/auth_callback',
    component: <AuthCallback />,
  },
  {
    path: '/legal-agreement',
    component: <LegalAgreement />,
  },
  {
    path: '/terms-conditions',
    component: <TermsAndConditions />,
  },
  {
    path: '/privacy-policy',
    component: <PrivacyPolicy />,
  },
  {
    path: '/details/:artifactName/:artifactVersion?/:widgetName?',
    component: <Details />,
  },
  {
    path: '/login',
    component: <Login />,
  },
  {
    path: '/register',
    component: <Register />,
  },
];

const privateRoutes = [
  {
    path: '/userProfile',
    component: <UserProfile />,
  },
  {
    path: '/shoppingCart',
    component: <ShoppingCart />,
  },
  {
    path: '/pricing-plans',
    component: <PricingPlans />,
  },
  {
    path: '/payment/:price_id',
    component: <Payment />,
  },
  {
    path: '/checkout',
    component: <Payment />,
  },
  {
    path: '/receipt/:receiptType',
    component: <Receipt />,
  },
  {
    path: '/pipelines',
    component: <Pipelines />,
  },
  {
    path: '/instance/:id?',
    component: <Credentials />,
  },
  {
    path: '/transfer-pipelines',
    component: <TransferPipelines />,
  },
  {
    path: '/verification-success',
    component: <EmailVerification />,
  },
  {
    path: '/logout',
    component: <Logout />,
  },
];

class App extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.currentPlan !== this.props.currentPlan &&
      this.props.currentPlan === Constants.SUBSCRIPTION_PLANS.ENTERPRISE
    ) {
      this.props.fetchInstances();
    }
  }

  renderRouter = (isUserLoggedIn, isEmailVerified) => {
    if (!isUserLoggedIn) {
      return (
        <Routes>
          {publicRoutes.map((route) => (
            <Route
              key={route.path}
              exact={route.path === '/'}
              path={route.path}
              element={route.component}
            />
          ))}
          <Route path="*" element={<Navigate to={'/'} />} />
        </Routes>
      );
    }
    if (!isEmailVerified) {
      return (
        <Routes>
          <Route path={'/'} element={<OssPlugins />} />
          <Route path={'/email-verification'} element={<EmailVerification />} />
          <Route path={'/userProfile'} element={<UserProfile />} />
          <Route path={'/logout'} element={<Logout />} />
          <Route path="*" element={<Navigate to={'/email-verification'} />} />
        </Routes>
      );
    }
    return (
      <Routes>
        {[...publicRoutes, ...privateRoutes].map((route) => (
          <Route
            key={route.path}
            exact={route.path === '/'}
            path={route.path}
            element={route.component}
          />
        ))}
        <Route path="*" element={<Navigate to={'/'} />} />
      </Routes>
    );
  };

  render() {
    const { userLoggedIn, isLoading, user } = this.props;

    const noFooterAndNavbar = ['/login', '/register'];

    if (isLoading) {
      return <LoadingSpinner />;
    }

    return (
      <div>
        {noFooterAndNavbar.indexOf(this.props.router.location.pathname) ===
          -1 && <Navbar />}

        <div style={{ minHeight: 'calc(100vh - 165px)' }}>
          {this.renderRouter(userLoggedIn, user?.verified_email)}
        </div>

        {noFooterAndNavbar.indexOf(this.props.router.location.pathname) ===
          -1 && <Footer />}
      </div>
    );
  }
}

const mapStateFromProps = (state) => ({
  userLoggedIn: state.user.userLoggedIn,
  currentPlan: state.user.currentPlan,
  isLoading: state.user.isLoading,
  user: state.user.currentUser,
});

const mapActionsToProps = {
  fetchInstances: pipelines.fetchInstances,
};

export default withRouter(connect(mapStateFromProps, mapActionsToProps)(App));
