import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pipelines } from '../../actions';

class ResponseErrorPopup extends Component {
  render() {
    return (
      <div className="popup">
        <div className="inerPopup col-lg-3 text-center">
          <p style={{ fontWeight: 'bold', marginTop: '10px' }}>
            {this.props.textToShow}
          </p>
          <div
            className="row"
            style={{ justifyContent: 'center', marginTop: '20px' }}
          >
            <button
              className="btn btn-light"
              onClick={() => {
                this.props.clearError();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  activeComponent: state.myPlugins.activeComponent,
});

const mapActionsToProps = {
  clearError: pipelines.clearError,
};
export default connect(mapStateToProps, mapActionsToProps)(ResponseErrorPopup);
