import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pipelines } from '../../../../actions';
import TransferPopup from '../../../TransferPopup';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
// import PublishIcon from '@mui/icons-material/Publish';

const styles = (theme) => ({
  root: {
    paddingLeft: '10px',
    paddingRight: '5px',
  },
  highlight: '#4791db'
    ? {
        backgroundColor: '#ebedf7',
        borderRadius: '3px',
      }
    : {
        color: 'primary.main',
        backgroundColor: '#bac6d1',
      },
  title: {
    flex: '1 1 45%',
  },
});

class EnhancedTableToolbar extends Component {
  state = {
    transferPopup: false,
    deletePopUp: false,
  };

  exportPipelinesHandler = async () => {
    if (this.props.instanceData.checkedPipelines.length > 0) {
      const { currentNamespace, uniqueId, checkedPipelines } =
        this.props.instanceData;
      const obj = { pipelinesList: checkedPipelines };

      await this.props.exportPipelines(
        // this.props.currentUser.accessToken,
        uniqueId,
        currentNamespace,
        obj
      );
      return;
    }
    return;
  };

  parse = async (file) => {
    const reader = new FileReader();
    reader.readAsText(file);
    const result = await new Promise((resolve, reject) => {
      reader.onload = function (event) {
        resolve(JSON.parse(reader.result));
      };
    });
    return result;
  };

  setMultiplePipelines = async (files) => {
    const uploadedPipelines = Promise.all(
      Array.from(files).map(async (file) => await this.parse(file))
    );
    return await uploadedPipelines;
  };

  uploadPipeline = async (event) => {
    const { instanceData } = this.props;
    const files = event.target.files;
    const data = await this.setMultiplePipelines(files);
    await this.props.uploadPipeline(
      instanceData.uniqueId,
      instanceData.currentNamespace,
      data
    );
    await this.props.fetchInstancePipelines(
      instanceData.uniqueId,
      instanceData.currentNamespace
      // this.props.currentUser.accessToken
    );
  };

  transferPopupHandler = () => {
    this.setState({
      transferPopup: !this.state.transferPopup,
    });
  };

  deletePipelines = () => {
    const { instanceData } = this.props;
    return (
      <div className="popup">
        <div className="inerPopup text-center" style={{ padding: '50px' }}>
          <p style={{ fontWeight: 'bold' }}>Are you sure you want to delete?</p>
          <div
            className="row"
            style={{ justifyContent: 'center', marginTop: '20px' }}
          >
            <button
              className="btn btn-primary"
              onClick={async () => {
                this.setState({
                  deletePopUp: !this.state.deletePopUp,
                });
                await this.props.deletePipelines(
                  instanceData.uniqueId,
                  instanceData.currentNamespace,
                  instanceData.checkedPipelines
                );
                await this.props.fetchInstancePipelines(
                  instanceData.uniqueId,
                  instanceData.currentNamespace
                );
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-light"
              onClick={() =>
                this.setState({
                  deletePopUp: !this.state.deletePopUp,
                })
              }
            >
              No
            </button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const { numSelected, handleSearch, instanceData } = this.props;
    return (
      <div>
        <Toolbar
          className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
          })}
        >
          {numSelected > 0 ? (
            <Typography
              className={classes.title}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected} selected
            </Typography>
          ) : (
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Pipelines
            </Typography>
          )}
          <div
            className="row"
            style={{ marginRight: '30px', marginTop: '15px' }}
          >
            {/* <Tooltip title="Import">
              <IconButton aria-label="import">
                <PublishIcon />
              </IconButton>
            </Tooltip> */}
            {numSelected > 0 ? (
              <div>
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      this.setState({
                        deletePopUp: !this.state.deletePopUp,
                      });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Start">
                  <IconButton
                    aria-label="start"
                    onClick={async () => {
                      await this.props.startPipelines(
                        // this.props.currentUser.accessToken,
                        instanceData.uniqueId,
                        instanceData.currentNamespace,
                        instanceData.checkedPipelines
                      );
                      await this.props.fetchInstancePipelines(
                        instanceData.uniqueId,
                        instanceData.currentNamespace
                        // this.props.currentUser.accessToken
                      );
                    }}
                  >
                    <PlayCircleFilledWhiteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Transfer">
                  <IconButton
                    aria-label="transfer"
                    onClick={async () => {
                      await this.transferPopupHandler();
                    }}
                  >
                    <i className="fa fa-exchange"></i>
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    numSelected > 1
                      ? 'Download as ZIP file.'
                      : 'Download as JSON file.'
                  }
                >
                  <IconButton
                    aria-label="export"
                    onClick={async () => {
                      await this.exportPipelinesHandler();
                      await this.props.fetchInstancePipelines(
                        instanceData.uniqueId,
                        instanceData.currentNamespace
                      );
                    }}
                  >
                    <i className="far fa-file-archive"></i>
                    <i
                      className={
                        numSelected > 1
                          ? 'fa fa-file-archive-o'
                          : 'fa fa-download'
                      }
                    ></i>
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <div>
                {' '}
                <input
                  id={'uploadPlugin' + this.props.instanceIndex}
                  type="file"
                  onChange={this.uploadPipeline}
                  accept=".json"
                  className="hide-input"
                  multiple
                />
                <label
                  htmlFor={'uploadPlugin' + this.props.instanceIndex}
                  style={{ marginBottom: '0px' }}
                >
                  <Tooltip title="Upload pipelines">
                    <IconButton component="span">
                      <i className="fa fa-upload" />
                    </IconButton>
                  </Tooltip>
                </label>
              </div>
            )}
          </div>
          <Tooltip title="Search by name">
            <TextField
              className="align-items-end"
              style={{ paddingRight: '10px' }}
              name="search"
              label="Search"
              color="primary"
              onChange={(event) => {
                handleSearch(event);
              }}
            />
          </Tooltip>
        </Toolbar>
        {this.state.transferPopup && (
          <TransferPopup
            actionType={'pipeline-transfer'}
            transferPopupHandler={this.transferPopupHandler.bind(this)}
            instanceData={instanceData}
            instanceIndex={this.props.instanceIndex}
          />
        )}
        {this.state.deletePopUp &&
          this.deletePipelines(
            instanceData.uniqueId,
            instanceData.currentNamespace,
            instanceData.checkedPipelines
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  pipelineData: state.pipelines.data,
  checkedPipelines: state.pipelines.checkedPipelines,
  isLoading: state.pipelines.isLoading,
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  activeComponent: state.myPlugins.activeComponent,
});

const mapActionsToProps = {
  exportPipelines: pipelines.exportPipelines,
  deletePipelines: pipelines.deletePipelines,
  startPipelines: pipelines.startPipelines,
  uploadPipeline: pipelines.uploadPipeline,
  fetchInstancePipelines: pipelines.fetchInstancePipelines,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(EnhancedTableToolbar));
