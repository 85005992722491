import React, { Component } from 'react';
import { connect } from 'react-redux';
import FaildIcon from '../../assets/images/failedIcon.png';

class PaymentFailed extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-lg-3">
          <img alt="Placeholder" src={FaildIcon} />
        </div>
        <div className="col-lg-7 payment">
          <div className="card-stats mb-4 mb-xl-0 pd-10">
            <div className="card-body bg-color-shoppingList rounder-borders">
              <div className="col-lg-12">
                <h3 className="text-center">
                  Your transaction could not be processed!
                </h3>
                <br />
                <br />
                <h4>Reason: The system is currently unavaliable.</h4>
                <h4>
                  Suggestion: If the problem is repeating, please conntact
                  AdaptiveScale Customer Service.
                </h4>
                <br />
                <div className="text-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      this.props.history.push('/');
                    }}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  price: state.shoppingCart.price,
  customerData: state.shoppingCart.customerData,
  error: state.shoppingCart.error,
  ...ownProps,
});
export default connect(mapStateToProps)(PaymentFailed);
