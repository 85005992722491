import * as actionTypes from '../actions/types';

const initialPaymentMethodsState = {
  error: null,
  data: [],
  products: [],
  attachResponse: null,
  isLoading: true,
  isLoadingProducts: true,
};

const paymentMethods = (state = initialPaymentMethodsState, action) => {
  switch (action.type) {
    case actionTypes.USER_PAYMENT_METHOD_FETCH_BEGIN:
      console.debug(actionTypes.USER_PAYMENT_METHOD_FETCH_BEGIN);
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.USER_PAYMENT_METHOD_FETCH_SUCCESS:
      console.debug(actionTypes.USER_PAYMENT_METHOD_FETCH_SUCCESS);
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    case actionTypes.USER_PAYMENT_METHOD_FETCH_FAIL:
      console.debug(actionTypes.USER_PAYMENT_METHOD_FETCH_FAIL);
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    case actionTypes.ATTACH_PAYMENT_METHOD_BEGIN:
      console.debug(actionTypes.ATTACH_PAYMENT_METHOD_BEGIN);
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.ATTACH_PAYMENT_METHOD_SUCCESS:
      console.debug(actionTypes.ATTACH_PAYMENT_METHOD_SUCCESS);
      return {
        ...state,
        attachResponse: action.payload.response,
        isLoading: false,
      };
    case actionTypes.ATTACH_PAYMENT_METHOD_FAIL:
      console.debug(actionTypes.ATTACH_PAYMENT_METHOD_FAIL);
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    case actionTypes.DETACH_PAYMENT_METHOD_BEGIN:
      console.debug(actionTypes.DETACH_PAYMENT_METHOD_BEGIN);
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.DETACH_PAYMENT_METHOD_SUCCESS:
      console.debug(actionTypes.DETACH_PAYMENT_METHOD_SUCCESS);
      return {
        ...state,
        attachResponse: action.payload.response,
        isLoading: false,
      };
    case actionTypes.DETACH_PAYMENT_METHOD_FAIL:
      console.debug(actionTypes.DETACH_PAYMENT_METHOD_FAIL);
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    case actionTypes.FETCH_PRODUCTS_BEGIN: {
      return {
        ...state,
        isLoadingProducts: true,
      };
    }
    case actionTypes.FETCH_PRODUCTS_SUCCESS: {
      return {
        ...state,
        isLoadingProducts: false,
        products: action.payload.data,
      };
    }
    default:
      return state;
  }
};

export default paymentMethods;
