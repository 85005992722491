const ACCESS_TOKEN_KEY = 'pm_access_token';
const ACCESS_TOKEN_QUERY_PARAM = 'token';
const REFRESH_TOKEN_KEY = 'pm_refresh_token';
const RES_ACCESS_TOKEN = 'access_token';
const RES_REFRESH_TOKEN = 'refresh_token';
const REDIRECR_AFTER_URL = 'redirect_after_url';

const SUBSCRIPTION_PLANS = {
  DEVELOPER: 'developer',
  TEAM: 'team',
  BUSINESS: 'business',
  ENTERPRISE: 'enterprise',
};

const constants = {
  ACCESS_TOKEN_KEY,
  ACCESS_TOKEN_QUERY_PARAM,
  REFRESH_TOKEN_KEY,
  RES_ACCESS_TOKEN,
  RES_REFRESH_TOKEN,
  SUBSCRIPTION_PLANS,
  REDIRECR_AFTER_URL,
};

export default constants;
