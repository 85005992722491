import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  SubscriptionReceipt,
  CancelSubscription,
  CheckoutReceipt,
} from '../../components';
import { shoppingCart } from '../../actions';
import { withRouter } from '../../hooks';

class Receipt extends Component {
  render() {
    return (
      <div className="container py-4">
        <div className="col-xl-12 col-lg-12">
          <div className="card-stats mb-4 mb-xl-0 pd-10">
            <div className="card-body bg-white rounder-borders card-body-shadow">
              <div className="row" style={{ marginTop: '20px' }}>
                <div className="col-lg-1 text-right">
                  <button className="btn-no-bg">
                    <i
                      className="fa fa-arrow-left button-click"
                      onClick={() => this.props.history.goBack()}
                      style={{ color: 'gray', cursor: 'pointer' }}
                    />
                  </button>
                </div>
                <div className="col-lg-5">
                  <h4>Receipt</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card-body">
                    <div className="col-lg-12">
                      <div className="col-lg-12 mgTopBottom border-top pd-10">
                        {this.props.router.location.pathname ===
                          '/receipt/checkout' && (
                          <CheckoutReceipt history={this.props.history} />
                        )}
                        {this.props.router.location.pathname ===
                          '/receipt/subscription' && (
                          <SubscriptionReceipt history={this.props.history} />
                        )}
                        {this.props.router.location.pathname ===
                          '/receipt/cancel-subscription' && (
                          <CancelSubscription history={this.props.history} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  price: state.shoppingCart.price,
  ...ownProps,
});

const mapActionsToProps = {
  clearShoppingCart: shoppingCart.clearShoppingCart,
};
export default withRouter(connect(mapStateToProps, mapActionsToProps)(Receipt));
