import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Constants } from '../../config';

const Card = ({
  priceId,
  name,
  price,
  features,
  currentPlan,
  currentPlanPrice,
  setPrice,
  cancelSubscription,
  image,
}) => {
  const navigate = useNavigate();

  const getActionButton = () => {
    if (name === Constants.SUBSCRIPTION_PLANS.ENTERPRISE) {
      return (
        <button
          className="btn btn-primary"
          onClick={() => {
            window.open('https://www.adaptivescale.com/#contact', '_blank');
          }}
        >
          Get a quote
        </button>
      );
    }

    if (name === currentPlan) {
      return name !== Constants.SUBSCRIPTION_PLANS.DEVELOPER ? (
        <button
          className="btn btn-outline-primary"
          onClick={cancelSubscription}
        >
          Cancel Subscription
        </button>
      ) : (
        <div className="current-plan">Current Plan</div>
      );
    }

    if (price && currentPlan !== Constants.SUBSCRIPTION_PLANS.ENTERPRISE) {
      const isDowngrade = currentPlanPrice > price;

      return (
        <button
          className={`btn ${isDowngrade ? 'btn-outline-primary' : 'btn-primary'}`}
          onClick={() => {
            setPrice(price);
            navigate(`/payment/${priceId}`);
          }}
        >
          {isDowngrade ? 'Downgrade' : 'Subscribe'}
        </button>
      );
    }

    return null;
  };

  return (
    <div className="s-price-card">
      <div>
        <div className="text-center mb-2">
          <img alt="plan-icon" src={image} height="42px" />
        </div>
        <h1
          className="text-center mb-2"
          style={{
            fontSize: '20px',
          }}
        >
          {name ? name.charAt(0).toUpperCase() + name.slice(1) : name}
        </h1>
        {name === Constants.SUBSCRIPTION_PLANS.ENTERPRISE ? (
          <h3
            className="text-center mb-2"
            style={{
              color: '#525f7f',
              padding: '10px 0px',
            }}
          >
            Contact sales for quote
          </h3>
        ) : (
          <h3
            className="text-center mb-4"
            style={{
              fontSize: '28px',
              color: '#525f7f',
            }}
          >
            ${price}
            <span style={{ fontSize: '12px' }}>/month</span>
          </h3>
        )}

        {features.map((feature, index) => (
          <div key={index + feature} className="included-items">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="18"
              height="18"
              fill="#007BFF"
              className="svg-pricing"
            >
              <path d="M20.285 4.715a1 1 0 0 1 0 1.414l-10 10a1 1 0 0 1-1.414 0l-5-5a1 1 0 1 1 1.414-1.414L10 13.586l9.285-9.285a1 1 0 0 1 1.414 0z" />
            </svg>
            {feature}
          </div>
        ))}
      </div>

      <div className="text-center">{getActionButton()}</div>
    </div>
  );
};

export { Card };
