import React, { Component } from 'react';
import { connect } from 'react-redux';
import { myPlugins, oss } from '../../actions';
import { set as setIn } from 'lodash';
import TextField from '@mui/material/TextField';
import TagsInput from 'react-tagsinput';

class EditPlugin extends Component {
  state = {
    description: this.props.pluginToEdit.description,
    contact: this.props.pluginToEdit.contact,
    infoURL: this.props.pluginToEdit.infoURL,
    tags: this.props.pluginToEdit.tags,
  };
  changeHandler = (event) => {
    const newState = setIn(this.state, event.target.name, event.target.value);
    this.setState({
      ...newState,
    });
  };
  render() {
    return (
      <div className="popup">
        <div className="inerPopup col-lg-3 text-center">
          <p style={{ fontWeight: 'bold' }}>Edit Plugin</p>
          <p style={{ marginTop: '20px' }}>
            Only these field's can be updated.
          </p>
          <div className="col-sm-12" style={{ marginTop: '15px' }}>
            <textarea
              style={{ height: '140px', marginTop: '10px' }}
              className="text-area-curl"
              placeholder="Description..."
              name="description"
              // required
              defaultValue={this.props.pluginToEdit.description}
              onChange={this.changeHandler}
            />
            <TextField
              style={{ marginTop: '10px' }}
              variant="outlined"
              name="contact"
              label="Support Email"
              color="primary"
              fullWidth
              onChange={this.changeHandler}
              defaultValue={this.props.pluginToEdit.contact}
              // required
            />
            <TextField
              style={{ marginTop: '10px' }}
              variant="outlined"
              name="infoURL"
              label="Info URL"
              color="primary"
              fullWidth
              onChange={this.changeHandler}
              defaultValue={this.props.pluginToEdit.infoURL}
              // required
            />
            <div style={{ marginTop: '10px', textAlign: 'left' }}>
              <TagsInput
                value={this.state.tags}
                name="tags"
                required
                onChange={(tags) =>
                  this.changeHandler({
                    target: {
                      name: 'tags',
                      value: tags,
                    },
                  })
                }
              />
            </div>
          </div>
          <div
            className="row"
            style={{ justifyContent: 'center', marginTop: '20px' }}
          >
            <button
              className="btn btn-primary"
              onClick={async () => {
                this.props.EditPluginPopupHandler(this.props.pluginToEdit);
                // await this.props.editPlugin(
                //   this.props.currentUser.uid,
                //   this.props.pluginToEdit.uniqueId,
                //   this.state,
                //   this.props.currentUser.accessToken
                // );
                await this.props.editPlugin(
                  this.props.pluginToEdit.uniqueId,
                  this.state,
                );
                await this.props.fetchMyPlugins();
                // await this.props.fetchMyPlugins(this.props.currentUser.uid);
                // this.props.fetchOss(
                //   this.props.currentUser.uid,
                //   this.props.authToken
                // );
                // this.props.fetchPagination(
                //   this.props.currentUser.uid,
                //   this.props.authToken
                // );
                this.props.paginateOss(1, 20, '', '', 'myPlugins');
              }}
            >
              Save
            </button>
            <button
              className="btn btn-light"
              onClick={() => {
                this.props.EditPluginPopupHandler(null);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  activeComponent: state.myPlugins.activeComponent,
  pluginToEdit: state.myPlugins.pluginToEdit,
});
const mapActionsToProps = {
  EditPluginPopupHandler: myPlugins.EditPluginPopupHandler,
  editPlugin: myPlugins.editPlugin,
  fetchMyPlugins: myPlugins.fetchMyPlugins,
  // fetchOss: oss.fetchOSS,
  // fetchPagination: oss.fetchPagination,
  paginateOss: oss.paginateOss,
};

export default connect(mapStateToProps, mapActionsToProps)(EditPlugin);
