import * as actionTypes from '../actions/types';
import { setDataToFilter } from '../helpers';

const initialOSSState = {
  error: null,
  data: [],
  allOss: [],
  isLoading: false,
  filteredData: [],
  commercialData: [],
  commercialFilteredData: [],
  myPlugins: [],
  myPluginsFilteredData: [],
  privateData: [],
  page: 1,
  fuseKeys: [
    { name: 'converted_name', weight: 0.4 },
    { name: 'widgets.name', weight: 0.4 },
    { name: 'widgets.data.display-name', weight: 0.4 },
    { name: 'tags', weight: 0.4 },
  ],
  fuseOptions: { shouldSort: true },
  artifactTypes: [],
  filters: {
    cdapVersion: '6.2.0',
    artifactType: 'all',
    showBundles: true,
  },
  itemsCount: '',
  keyword: '',
  activeTab: 'all',
  paginationIsLoading: false,
  types: [],
  hasMoreData: true,
};

const oss = (state = initialOSSState, action, dispatch) => {
  switch (action.type) {
    case actionTypes.BEGIN_TYPES_FETCH: {
      return {
        ...state,
        types: [],
      };
    }
    case actionTypes.TYPES_FETCH_SUCCESS: {
      return {
        ...state,
        types: action.payload.data,
      };
    }
    case actionTypes.BEGIN_PAGINATION: {
      return {
        ...state,
        isLoading: state.page === 1,
        paginationIsLoading: true,
      };
    }
    case actionTypes.PAGINATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        paginationIsLoading: false,
        itemsCount: action.payload.total_count,
        data:
          action.payload.page === 1
            ? action.payload.packages.map((pkg) => pkg.data)
            : [
                ...state.data,
                ...action.payload.packages.map((pkg) => pkg.data),
              ],
        hasMoreData: !(action.payload.count < 20),
        page: action.payload.page,
      };
    }
    case actionTypes.OSS_SEARCH: {
      // dispatch(paginateOss(1, 20, action.payload.keyword));
      return { ...state };
    }
    case actionTypes.SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload.activeTab,
      };
    }
    case actionTypes.SET_OSS_FILTERS: {
      console.debug(actionTypes.SET_OSS_FILTERS, action);
      const { filters } = action.payload;
      const keyword = state.keyword;
      return setDataToFilter(state, keyword, filters, initialOSSState);
    }
    case actionTypes.UPDATE_PURCHASE_STATUS: {
      console.debug(actionTypes.UPDATE_PURCHASE_STATUS, action);
      const updatedData = state.data.map((artifact) =>
        action.payload.purchasedArtifacts.indexOf(artifact.uniqueId) !== -1
          ? { ...artifact, isAlreadyPurchased: true }
          : artifact
      );
      const filteredData = state.filteredData.map((artifact) =>
        action.payload.purchasedArtifacts.indexOf(artifact.uniqueId) !== -1
          ? { ...artifact, isAlreadyPurchased: true }
          : artifact
      );
      return {
        ...state,
        data: updatedData,
        filteredData,
      };
    }
    case actionTypes.SET_ITEMS_COUNT: {
      console.debug(actionTypes.SET_ITEMS_COUNT, action);
      return {
        ...state,
        itemsCount: action.payload.itemsNr,
      };
    }
    case actionTypes.BEGIN_MYPLUGINS_FETCH: {
      console.info(actionTypes.BEGIN_MYPLUGINS_FETCH);
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionTypes.MYPLUGINS_FETCH_SUCCESS: {
      console.info(actionTypes.MYPLUGINS_FETCH_SUCCESS);
      return {
        ...state,
        error: null,
        isLoading: false,
        myPlugins: action.payload.data,
        myPluginsFilteredData: action.payload.data,
      };
    }
    case actionTypes.MYPLUGINS_FETCH_FAIL: {
      console.info(actionTypes.MYPLUGINS_FETCH_FAIL);
      return {
        ...state,
        error: action.payload.error,
      };
    }
    default: {
      return state;
    }
  }
};
export default oss;
