import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const history = {
      push: navigate,
      goBack: () => navigate(-1),
    };

    return (
      <Component
        {...props}
        history={history}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}
