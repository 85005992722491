import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ShoppingCartHeader, CDAPCredentials } from '../../components';
import { withRouter } from '../../hooks';

class Credentials extends Component {
  render() {
    const { pathname } = this.props.router.location;
    return (
      <div className="container py-4">
        <div className="col-xl-12 col-lg-12">
          <div className="card-stats mb-4 mb-xl-0 pd-10">
            <div className="card-body bg-white rounder-borders card-body-shadow">
              <ShoppingCartHeader
                goBack={() => {
                  this.props.userInstances.length === 0
                    ? this.props.history.push('/')
                    : this.props.history.goBack();
                }}
                history={this.props.history}
                componentName={
                  pathname === '/instance' ? 'Add Instance' : 'Edit Instance'
                }
              />
              <div className="row">
                <div className="col-lg-12">
                  <div className="card-body">
                    <div className="col-lg-11 mgTopBottom border-top pd-10">
                      <CDAPCredentials />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userInstances: state.pipelines.userInstances,
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  currentPlan: state.user.currentPlan,
  // ...ownProps,
});
export default withRouter(connect(mapStateToProps)(Credentials));
