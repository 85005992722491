import React, { Component } from 'react';
import { connect } from 'react-redux';
import ShoppingCartHeader from '../../components/ShoppingCartHeader';
import { StripeProvider, Elements } from 'react-stripe-elements';
import SecureCheckout from '../../components/SecureCheckout';
import { Properties } from '../../config';
import { withRouter } from '../../hooks';
import { client } from '../../config/api';
import { LoadingSpinner } from '../../components';

class Payment extends Component {
  state = {
    isLoading: true,
    proration: undefined,
  };
  calculateProration = async () => {
    if (
      this.props.router?.params?.price_id &&
      this.props.currentUser?.current_subscription
    ) {
      try {
        const prorationRes = await client.get(
          `/proration/${this.props.router?.params?.price_id}`
        );
        this.setState({
          ...this.state,
          proration: prorationRes.data,
          isLoading: false,
        });
      } catch (e) {
        this.setState({
          ...this.state,
          isLoading: false,
        });
      }
      return;
    }
    this.setState({
      ...this.state,
      isLoading: false,
    });
  };

  componentDidMount = async () => {
    if (this.props.currentUser) {
      await this.calculateProration();
    }
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    }
    return (
      <div className="container py-4">
        <div className="col-xl-12 col-lg-12">
          <div className="card-stats mb-4 mb-xl-0 pd-10">
            <div className="card-body bg-white rounder-borders card-body-shadow">
              <ShoppingCartHeader
                goBack={() => {
                  this.props.history.goBack();
                }}
                history={this.props.history}
                componentName="Pricing Details"
              />
              <div className="row">
                <div className="col-lg-12">
                  <div className="card-body">
                    <div className="col-lg-12">
                      <div className="col-lg-12 mgTopBottom border-top pd-10">
                        <StripeProvider apiKey={Properties.STRIPE_API_KEY}>
                          <Elements>
                            <SecureCheckout
                              pathname={this.props.router.location.pathname}
                              history={this.props.history}
                              router={this.props.router}
                              proration={this.state.proration}
                            />
                          </Elements>
                        </StripeProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  ...ownProps,
});
export default withRouter(connect(mapStateToProps)(Payment));
