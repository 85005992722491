import React, { Component } from 'react';
import { connect } from 'react-redux';

class DeletePopup extends Component {
  render() {
    return (
      <div className="popup">
        <div className="inerPopup col-lg-3 text-center">
          <p style={{ fontWeight: 'bold' }}>
            The instance "{this.props.instanceName}" will be deleted.
          </p>
          <p style={{ fontWeight: 'bold' }}>
            Are you sure you want to continue?
          </p>
          <div
            className="row"
            style={{ justifyContent: 'center', marginTop: '20px' }}
          >
            <button
              className="btn btn-primary"
              onClick={() => {
                this.props.deleteInstance({ instance_id: this.props.uniqueId },);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-light"
              onClick={() => {
                this.props.popupHandler();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  activeComponent: state.myPlugins.activeComponent,
  instance: state.myPlugins.instance,
});
const mapActionsToProps = {};
export default connect(mapStateToProps, mapActionsToProps)(DeletePopup);
