import React, { Component } from 'react';
import { userActions } from '../../actions/index';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { shoppingCart } from '../../actions';
import { withRouter } from '../../hooks';
import { Properties, Constants } from '../../config';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      errors: [],
      loading: false,
      showPassword: false,
    };
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  isFormEmpty = ({ email, password, confirmPassword }) => {
    return !email.length || !password.length || !confirmPassword.length;
  };
  isEmailValid = (email) => {
    // eslint-disable-next-line no-useless-escape
    const allowed =
      /^[a-zA-Z0-9._-]+(\+[0-9]+)?@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return allowed.test(email);
  };

  isPasswordValid = () => {
    if (this.state.password.length < 6 || this.state.confirmPassword.length < 6)
      return true;
    else return false;
  };

  isPasswordMatch = () => {
    if (this.state.password !== this.state.confirmPassword) return true;
    else return false;
  };

  isFormValid = () => {
    let errors = [];
    let error;
    if (this.isFormEmpty(this.state)) {
      error = { message: 'Fill in all fields!' };
      this.setState({ errors: errors.concat(error) });
      return false;
    } else if (!this.isEmailValid(this.state.email)) {
      error = { message: 'Email is not valid!' };
      this.setState({ errors: errors.concat(error) });
      return false;
    } else if (this.isPasswordValid(this.state)) {
      error = { message: 'Password Length must be greater than 6! characters' };
      this.setState({ errors: errors.concat(error) });
      return false;
    } else if (this.isPasswordMatch(this.state)) {
      error = { message: 'Password is not matching!' };
      this.setState({ errors: errors.concat(error) });
      return false;
    } else {
      console.debug('isFormValid->User Registered!');
      return true;
    }
  };

  displayErrors = (error) => {
    return error.map((error, i) => <p key={i}>{error.message}</p>);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.props.setErrorToNull();
    if (this.isFormValid(this.state)) {
      console.log('here');
      this.setState({ errors: [], loading: true });
      await this.props.regularSignUp(this.state.email, this.state.password);
      if (this.props.error) {
        this.setState({
          errors: this.state.errors.concat(this.props.error),
          loading: false,
        });
        return;
      }
      const redirect_url = localStorage.getItem(Constants.REDIRECR_AFTER_URL);
      localStorage.removeItem(Constants.REDIRECR_AFTER_URL);
      if (redirect_url) {
        this.props.history.push(redirect_url);
      } else {
        this.props.history.push('/');
      }
    }
  };

  render() {
    const { email, password, confirmPassword } = this.state;
    if (this.props.userLoggedIn) {
      return <div style={{ padding: '20px' }}>You are already signed in.</div>;
    }
    return (
      <div
        style={{
          background: `linear-gradient(135deg, #172B4D, #31496D, #172B4D, #31496D, #172B4D)`,
          backgroundSize: `cover`,
          minHeight: '100vh',
          padding: '16px',
        }}
      >
        <div className="auth-header">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-6 text-center">
              <img
                src="https://d33wubrfki0l68.cloudfront.net/23317a66889fb01027aeb64af443fce2c03623b4/74d74/adaptivescale/img/logo.png"
                alt=""
                style={{
                  width: '150px',
                  padding: '0px 0px 10px 0px',
                  cursor: 'pointer',
                }}
                onClick={() => this.props.router.navigate('/')}
              />
              <div style={{ marginBottom: '20px' }}>
                <center>
                  <p className="text-white" style={{ fontSize: '25px' }}>
                    Data Integration Cloud
                  </p>
                </center>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            background: '#f8f9fe',
            width: '100%',
            maxWidth: '400px',
            borderRadius: '4px',
            margin: '0px auto',
          }}
        >
          <div className="p-4">
            <div className="">
              <button className="btn-no-bg">
                <i
                  className="fa fa-arrow-left button-click"
                  onClick={() => this.props.router.navigate('/')}
                  style={{ color: 'gray', cursor: 'pointer' }}
                />
              </button>
            </div>
            <div className="">
              <div className="text-muted text-lg text-center mb-2">
                <small> Sign up with in with</small>
              </div>
              <div
                className="text-center"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '10px 0',
                }}
              >
                <a
                  href={`${Properties.API_HOST}auth/google/login/`}
                  className="btn btn-neutral btn-icon"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '8px',
                    width: '150px',
                    padding: '8px',
                  }}
                >
                  <div className="">
                    <img
                      src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                      alt=""
                    />
                  </div>
                  <div style={{ fontSize: '18px', color: 'black' }}>Google</div>
                </a>
              </div>
            </div>
            <div className="">
              <div className="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div>
              <form onSubmit={(event) => this.handleSubmit(event)}>
                <div className="form-group mb-3">
                  <FormControl
                    className="input-group bg-white"
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Email
                    </InputLabel>
                    <OutlinedInput
                      name="email"
                      label="Email"
                      value={email}
                      onChange={(event) => this.handleChange(event)}
                      id="standard-start-adornment"
                    />
                  </FormControl>
                </div>
                <div className="form-group mb-3">
                  <FormControl
                    className="input-group bg-white"
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      value={password}
                      name="password"
                      label="Password"
                      type={this.state.showPassword ? 'text' : 'password'}
                      onChange={(event) => this.handleChange(event)}
                      id="standard-start-adornment"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              this.handleClickShowPassword();
                            }}
                            edge="end"
                          >
                            {this.state.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>

                <div className="form-group mb-3">
                  <FormControl
                    className="input-group bg-white"
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Confirm password
                    </InputLabel>
                    <OutlinedInput
                      value={confirmPassword}
                      name="confirmPassword"
                      label="Confirm password"
                      type={this.state.showPassword ? 'text' : 'password'}
                      onChange={(event) => this.handleChange(event)}
                      id="standard-start-adornment"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              this.handleClickShowPassword();
                            }}
                            edge="end"
                          >
                            {this.state.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
                <div className="mt-2">
                  {this.state.errors.length > 0 && (
                    <div
                      className="login-error"
                      style={{ color: '#d86c6c', overflowWrap: 'break-word' }}
                    >
                      {this.displayErrors(this.state.errors)}
                    </div>
                  )}
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-primary my-4"
                    style={{
                      width: '150px',
                      fontSize: '18px',
                    }}
                    disabled={this.state.loading}
                  >
                    {this.state.loading ? 'Loading...' : 'Sign Up'}
                  </button>
                </div>
              </form>
              <div className="row my-3">
                <div className="col-12 text-center">
                  You already have an account?{' '}
                  <Link to="/login" className="text-center">
                    Sign in.
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateFromProps = (state) => ({
  userLoggedIn: state.user.userLoggedIn,
  isLoading: state.user.isLoading,
  error: state.user.error,
});

const mapActionsToProps = {
  setUser: userActions.setUser,
  regularSignUp: userActions.regularSignUp,
  setErrorToNull: userActions.setErrorToNull,
  setPrice: shoppingCart.setPrice,
};

export default withRouter(
  connect(mapStateFromProps, mapActionsToProps)(Register)
);
