import React, { Component } from 'react';
import { connect } from 'react-redux';
import Skeleton from '@mui/lab/Skeleton';

class SkeletonComp extends Component {
  skeletonCardView = () => {
    const items = [];
    for (var i = 0; i < 15; i++) {
      items.push(
        <div style={{ marginLeft: '20px', marginTop: '5px' }} key={i}>
          <Skeleton variant="text" height={8} />
          <Skeleton
            style={{
              marginLeft: '57px',
              marginBottom: '10px',
              marginTop: '10px',
            }}
            variant="circle"
            width={75}
            height={75}
          />
          <Skeleton
            variant="text"
            height={40}
            width={150}
            style={{ marginLeft: '20px', marginBottom: '5px' }}
          />
          <Skeleton variant="text" height={40} width={190} />
        </div>
        //   <div style={{ marginLeft: '20px', marginTop: '5px' }}>
        //     <Skeleton variant="text" height={8} />
        //     <Skeleton
        //       style={{
        //         marginLeft: '57px',
        //         marginBottom: '10px',
        //         marginTop: '10px',
        //       }}
        //       variant="circle"
        //       width={75}
        //       height={75}
        //     />
        //     <Skeleton variant="rect" width={190} height={100} />
        //   </div>
      );
    }
    return (
      <div>
        <div className="row">{items}</div>
      </div>
    );
  };

  skeletonListView = () => {
    const items = [];
    for (var i = 0; i < 10; i++) {
      items.push(
        <div
          className="row"
          style={{ marginLeft: '20px', marginBottom: '30px' }}
          key={i}
        >
          <Skeleton
            style={{
              marginRight: '20px',
              marginBottom: '10px',
              marginTop: '20px',
            }}
            variant="circle"
            width={100}
            height={100}
          />
          <div>
            <div style={{ display: 'flex' }}>
              <Skeleton variant="text" height={40} width={250} />
              <Skeleton
                style={{ marginLeft: '100px', marginTop: '11px' }}
                variant="text"
                height={20}
                width={50}
              />
            </div>
            <Skeleton variant="text" height={20} width={150} />
            <Skeleton variant="rect" width={870} height={60} />
            <Skeleton
              variant="text"
              height={10}
              width={500}
              style={{ paddingTop: '5px' }}
            />
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="row">{items}</div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.props.activeViewType === 'list'
          ? this.skeletonListView()
          : this.skeletonCardView()}
      </div>
    );
  }
}

const mapStateFromProps = (state) => ({
  activeViewType: state.activeState.activeViewType,
});

export default connect(mapStateFromProps)(SkeletonComp);
