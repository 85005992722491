import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  PaymentMethodsList,
  LoadingSpinner,
  UserProfileInfo,
} from '../../components';
import { Paper, Tabs, Tab } from '@mui/material';
import { withRouter } from '../../hooks';

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'AccountInfo',
    };
  }

  navTabChange(event, value) {
    this.setState({ activeTab: value });
  }

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    if (!this.props.userLoggedIn) {
      return <div>Please login</div>;
    }
    if (this.props.isLoading) {
      return <div>Loading...</div>;
    }
    if (this.props.userProfile === null) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 100px)',
          }}
        >
          <LoadingSpinner />
        </div>
      );
    }

    return (
      <div className=" container py-4">
        <div className="col-xl-12 col-lg-12">
          <div className="card-stats mb-4 mb-xl-0 pd-10">
            <div className="card-body bg-white rounder-borders card-body-shadow">
              <div className="row" style={{ marginTop: '20px' }}>
                <div className="col-lg-1 text-right">
                  <button className="btn-no-bg">
                    <i
                      className="fa fa-arrow-left button-click"
                      onClick={() => this.goBack()}
                      style={{ color: 'gray', cursor: 'pointer' }}
                    />
                  </button>
                </div>
                <div className="col-lg-4">
                  <h4>User Profile</h4>
                  <div>Secret key: {this.props.currentUser.email}</div>
                </div>
                <div className="col-lg-5">
                  <div className="f-absolute-right">
                    <div className="row">
                      <div
                        className="col-lg-5 text-right"
                        style={{ padding: '0px' }}
                      >
                        <div>
                          <h5>Current Plan</h5>
                          <h4
                            className="bold-text"
                            style={{ marginBottom: '0.1rem' }}
                          >
                            {this.props.currentPlan
                              ? this.props.currentPlan.charAt(0).toUpperCase() +
                                this.props.currentPlan.slice(1)
                              : 'Free'}
                          </h4>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <button
                          className="btn btn-primary btn-upgrade"
                          style={{ marginTop: '6px' }}
                          onClick={() => {
                            this.props.history.push('/pricing-plans');
                          }}
                        >
                          Change plan
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card-body">
                    <div className="col-lg-12">
                      <div
                        className="col-lg-12 border-top"
                        style={{ margin: '20px 0px' }}
                      >
                        <div className="container pd-10">
                          <div className="nav-wrapper col-lg-12">
                            <Paper className="paper-nav-tabs">
                              <Tabs
                                value={this.state.activeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={(event, value) => {
                                  this.navTabChange(event, value);
                                }}
                              >
                                <Tab
                                  label="Account information"
                                  value="AccountInfo"
                                />
                                <Tab
                                  label="Payment information"
                                  value="PaymentMethods"
                                />
                              </Tabs>
                            </Paper>
                          </div>
                          {this.state.activeTab === 'AccountInfo' ? (
                            <div className="col-lg-12">
                              <div
                                className="bg-color-shoppingList"
                                style={{
                                  border: '1px solid #efebeb',
                                  borderRadius: '5px',
                                  marginTop: '30px',
                                }}
                              >
                                <UserProfileInfo
                                  authToken={this.props.userProfile.authToken}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="col-lg-12">
                              <div
                                className="bg-color-shoppingList"
                                style={{
                                  border: '1px solid #efebeb',
                                  borderRadius: '5px',
                                  marginTop: '30px',
                                }}
                              >
                                <PaymentMethodsList />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  isLoading: state.user.isLoading,
  secretKey: state.myPlugins.secretKey,
  currentPlan: state.user.currentPlan,
  userProfile: state.user.userProfile,
  user: state.user,
});
export default withRouter(connect(mapStateToProps)(UserProfile));
