import {
  USER_PAYMENT_METHOD_FETCH_BEGIN,
  USER_PAYMENT_METHOD_FETCH_SUCCESS,
  USER_PAYMENT_METHOD_FETCH_FAIL,
  ATTACH_PAYMENT_METHOD_BEGIN,
  ATTACH_PAYMENT_METHOD_SUCCESS,
  ATTACH_PAYMENT_METHOD_FAIL,
  DETACH_PAYMENT_METHOD_BEGIN,
  DETACH_PAYMENT_METHOD_FAIL,
  DETACH_PAYMENT_METHOD_SUCCESS,
  SET_DEFAULT_PAYMENT_METHOD_BEING,
  SET_DEFAULT_PAYMENT_METHOD_SUCESS,
  SET_DEFAULT_PAYMENT_METHOD_FAIL,
  FETCH_PRODUCTS_BEGIN,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAIL,
} from './types';
import { client } from '../config/api';

const fetchUserPaymentMethodBegin = () => ({
  type: USER_PAYMENT_METHOD_FETCH_BEGIN,
});

const fetchUserPaymentMethodSuccess = (data) => ({
  type: USER_PAYMENT_METHOD_FETCH_SUCCESS,
  payload: { data },
});

const fetchUserPaymentMethodFail = (error) => ({
  type: USER_PAYMENT_METHOD_FETCH_FAIL,
  payload: { error },
});

export const fetchUserPaymentMethod = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchUserPaymentMethodBegin());
      // const res = await fetch(
      //   `${Properties.API_HOST}u/${currentUserId}/payment-methods`,
      //   {
      //     headers: {
      //       'Content-Type': 'application/json',
      //       Authorization: authToken,
      //     },
      //   }
      // );
      // const response = await res.json();
      // dispatch(fetchUserPaymentMethodSuccess(response));
      // return response;
      const res = await client.get(`u/payment-methods`);
      dispatch(fetchUserPaymentMethodSuccess(res.data));
      return res.data;
    } catch (error) {
      return dispatch(fetchUserPaymentMethodFail(error));
    }
  };
};

const attachPaymentMethodBegin = () => ({
  type: ATTACH_PAYMENT_METHOD_BEGIN,
});

const attachPaymentMethodSuccess = (response) => ({
  type: ATTACH_PAYMENT_METHOD_SUCCESS,
  payload: { response },
});

const attachPaymentMethodFail = (error) => ({
  type: ATTACH_PAYMENT_METHOD_FAIL,
  payload: { error },
});

export const attachPaymentMethod = (
  currentUserId,
  authToken,
  paymentMethod
) => {
  return async (dispatch) => {
    try {
      dispatch(attachPaymentMethodBegin());
      const res = await client.post(`u/payment-methods`, paymentMethod);
      dispatch(attachPaymentMethodSuccess(res.data));
      dispatch(fetchUserPaymentMethod());
      return res.data;
    } catch (error) {
      return dispatch(attachPaymentMethodFail(error));
    }
  };
};

const detachPaymentMethodBegin = () => ({
  type: DETACH_PAYMENT_METHOD_BEGIN,
});

const detachPaymentMethodSuccess = (response) => ({
  type: DETACH_PAYMENT_METHOD_SUCCESS,
  payload: { response },
});

const detachPaymentMethodFail = (error) => ({
  type: DETACH_PAYMENT_METHOD_FAIL,
  payload: { error },
});

export const detachPaymentMethod = (paymentMethod) => {
  return async (dispatch) => {
    try {
      dispatch(detachPaymentMethodBegin());
      // const res = await fetch(
      //   `${Properties.API_HOST}u/${currentUserId}/payment-methods/${paymentMethod.id}`,
      //   {
      //     method: 'DELETE',
      //     headers: {
      //       'Content-Type': 'application/json',
      //       Authorization: authToken,
      //     },
      //   }
      // );
      // const response = await res.json();
      // dispatch(detachPaymentMethodSuccess(response));
      // dispatch(fetchUserPaymentMethod(currentUserId, authToken));
      // return response;
      const res = await client.delete(`u/payment-methods/${paymentMethod.id}`);
      dispatch(detachPaymentMethodSuccess(res.data));
      dispatch(fetchUserPaymentMethod());
      return res.data;
    } catch (error) {
      return dispatch(detachPaymentMethodFail(error));
    }
  };
};

const setDefaultPaymentMethodBegin = () => ({
  type: SET_DEFAULT_PAYMENT_METHOD_BEING,
});

const setDefaultPaymentMethodSuccess = (response) => ({
  type: SET_DEFAULT_PAYMENT_METHOD_SUCESS,
  payload: { response },
});

const setDefaultPaymentMethodFail = (error) => ({
  type: SET_DEFAULT_PAYMENT_METHOD_FAIL,
  payload: { error },
});

export const setDefaultPaymentMethod = (paymentMethod) => {
  return async (dispatch) => {
    try {
      dispatch(setDefaultPaymentMethodBegin());
      // const res = await fetch(
      //   `${Properties.API_HOST}u/${currentUserId}/payment-methods/${paymentMethod.id}`,
      //   {
      //     method: 'PUT',
      //     headers: {
      //       'Content-Type': 'application/json',
      //       Authorization: authToken,
      //     },
      //   }
      // );
      // const response = await res.json();
      // dispatch(setDefaultPaymentMethodSuccess(response));
      // dispatch(fetchUserPaymentMethod(currentUserId, authToken));
      // return response;
      const res = await client.put(`u/payment-methods/${paymentMethod.id}`);
      dispatch(setDefaultPaymentMethodSuccess(res.data));
      dispatch(fetchUserPaymentMethod());
      return res.data;
    } catch (error) {
      return dispatch(setDefaultPaymentMethodFail(error));
    }
  };
};

const fetchProductsBegin = () => ({
  type: FETCH_PRODUCTS_BEGIN,
});

const fetchProductsSuccess = (data) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: { data },
});

const fetchProductsFail = (error) => ({
  type: FETCH_PRODUCTS_FAIL,
  payload: { error },
});

export const fetchProducts = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchProductsBegin());
      const response = await client.get('/plans/');
      dispatch(fetchProductsSuccess(response.data));
    } catch (e) {
      dispatch(fetchProductsFail(e));
    }
  };
};
