//users
export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const LOGGED_IN = 'LOGGED_IN';
export const CURRENT_USER = 'CURRENT_USER';
export const SET_USER_STATE = 'SET_USER_STATE';
export const SET_USER_PLAN = 'SET_USER_PLAN';
export const USER_SIGN_IN_BEGIN = 'USER_SIGN_IN_BEGIN';
export const USER_SIGN_IN_SUCCESS = 'USER_SIGN_IN_SUCCESS';
export const USER_SIGN_IN_FAIL = 'USER_SIGN_IN_FAIL';
export const USER_SIGN_OUT = 'USER_SIGN_OUT';
export const FETCH_USER_PROFILE_FAILED = 'FETCH_USER_PROFILE_FAILED';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const CLEAR_ERROR = 'CLEAR_ERROR';

//OSS
export const BEGIN_OSS_FETCH = 'BEGIN_OSS_FETCH';
export const OSS_FETCH_SUCCESS = 'OSS_FETCH_SUCCESS';
export const OSS_FETCH_FAIL = 'OSS_FETCH_FAIL';
export const SET_ITEMS_COUNT = 'SET_ITEMS_COUNT';
export const SET_OSS_FILTERS = 'SET_OSS_FILTERS';
export const UPDATE_PURCHASE_STATUS = 'UPDATE_PURCHASE_STATUS';
export const FIRST_BEGIN_PAGINATION_FETCH = 'FIRST_BEGIN_PAGINATION_FETCH';
export const BEGIN_PAGINATION_FETCH_CONTINUE =
  'BEGIN_PAGINATION_FETCH_CONTINUE';
export const PAGINATION_FETCH_SUCCESS = 'PAGINATION_FETCH_SUCCESS';
export const PAGINATION_FETCH_FAIL = 'PAGINATION_FETCH_FAIL';
export const BEGIN_TYPES_FETCH = 'BEGIN_TYPES_FETCH';
export const TYPES_FETCH_SUCCESS = 'TYPES_FETCH_SUCCESS';
export const TYPES_FETCH_FAIL = 'TYPES_FETCH_FAIL';
export const BEGIN_PAGINATION = 'BEGIN_PAGINATION';
export const PAGINATION_SUCCESS = 'PAGINATION_SUCCESS';
export const PAGINATION_FAIL = 'PAGINATION_FAIL';

//SEARCH
export const OSS_SEARCH = 'OSS_SEARCH';
export const COMMERCIAL_SEARCH = 'COMMERCIAL_SEARCH';
export const MYPLUGINS_SEARCH = 'MYPLUGINS_SEARCH';

//DETAILS
export const BEGIN_DETAILS_FETCH = 'BEGIN_DETAILS_FETCH';
export const JSON_FETCH_SUCCESS = 'JSON_FETCH_SUCCESS';
export const DETAILS_FETCH_FAIL = 'DETAILS_FETCH_FAIL';
export const SET_DETAILS_ARTIFACT = 'SET_DETAILS_ARTIFACT';
export const RESET_DETAILS_DATA = 'RESET_DETAILS_DATA';
export const SET_DETAILS_ARTIFACT_TAB = 'SET_DETAILS_ARTIFACT_TAB';
export const SET_DETAILS_ARTIFACT_SUB_TAB = 'SET_DETAILS_ARTIFACT_SUB_TAB';
export const SET_DETAILS_ARTIFACT_JSON_FILE_CONTENT =
  'SET_DETAILS_ARTIFACT_JSON_FILE_CONTENT';
export const SET_DETAILS_WIDGET_DOC = 'SET_DETAILS_WIDGET_DOC';
export const SET_DETAILS_ARTIFACT_DOC = 'SET_DETAILS_ARTIFACT_DOC';
export const CLEAR_DETAILS = 'CLEAR_DETAILS';
export const SET_DETAILS_WIDGET_INDEX = 'SET_DETAILS_WIDGET_INDEX';
export const SET_WIDGET_NAME = 'SET_WIDGET_NAME';

// MYPLUGINS
export const BEGIN_MYPLUGINS_FETCH = 'BEGIN_MYPLUGINS_FETCH';
export const MYPLUGINS_FETCH_SUCCESS = 'MYPLUGINS_FETCH_SUCCESS';
export const MYPLUGINS_FETCH_FAIL = 'MYPLUGINS_FETCH_FAIL';
export const CHANGE_MY_PLUGIN_STATE = 'CHANGE_MY_PLUGIN_STATE';
export const UPLOAD_PLUGIN_BEGIN = 'UPLOAD_PLUGIN_BEGIN';
export const UPLOAD_PLUGIN_SUCCESS = 'UPLOAD_PLUGIN_SUCCESS';
export const UPLOAD_PLUGIN_FAIL = 'UPLOAD_PLUGIN_FAIL';
export const SET_TOGGAL_MODAL = 'SET_TOGGAL_MODAL';
export const UPLOAD_PLUGIN_PERCENTAGE_UPDATE =
  'UPLOAD_PLUGIN_PERCENTAGE_UPDATE';
export const EDIT_POPUP_HANDLER = 'EDIT_POPUP_HANDLER';
export const DELETE_POPUP_HANDLER = 'DELETE_POPUP_HANDLER';
export const EDIT_PLUGIN_BEGIN = 'EDIT_PLUGIN_BEGIN';
export const EDIT_PLUGIN_SUCCESS = 'EDIT_PLUGIN_SUCCESS';
export const EDIT_PLUGIN_FAIL = 'EDIT_PLUGIN_FAIL';
export const DELETE_PLUGIN_BEGIN = 'DELETE_PLUGIN_BEGIN';
export const DELETE_PLUGIN_SUCCESS = 'DELETE_PLUGIN_SUCCESS';
export const DELETE_PLUGIN_FAIL = 'DELETE_PLUGIN_FAIL';

// active state
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const SET_DETAILS_SELECTED_INDEX = 'SET_DETAILS_SELECTED_INDEX';
export const SET_ACTIVE_TAB_PIPELINE = 'SET_ACTIVE_TAB_PIPELINE';

//shoppingCart
export const SHOPPING_CART_FETCH_BEGIN = 'SHOPPING_CART_FETCH_BEGIN';
export const SHOPPING_CART_FETCH_SUCCESS = 'SHOPPING_CART_FETCH_SUCCESS';
export const SHOPPING_CART_FETCH_FAIL = 'SHOPPING_CART_FETCH_FAIL';
export const SHOPPING_CART_ADD_SUCCESS = 'SHOPPING_CART_ADD_SUCCESS';
export const SHOPPING_CART_ADD_FAIL = 'SHOPPING_CART_ADD_FAIL';
export const SHOPPING_CART_REMOVE_SUCCESS = 'SHOPPING_CART_REMOVE_SUCCESS';
export const SHOPPING_CART_REMOVE_FAIL = 'SHOPPING_CART_REMOVE_FAIL';
export const SET_CHECKOUT_PRICE = 'SET_CHECKOUT_PRICE';
export const CHARGE_FETCH_BEGIN = 'CHARGE_FETCH_BEGIN';
export const CHARGE_FETCH_SUCCESS = 'CHARGE_FETCH_SUCCESS';
export const CHARGE_FETCH_FAIL = 'CHARGE_FETCH_FAIL';
export const CLEAR_SHOPPING_CART = 'CLEAR_SHOPPING_CART';
export const SUBSCRIPTION_FETCH_BEGIN = 'SUBSCRIPTION_FETCH_BEGIN';
export const SUBSCRIPTION_FETCH_SUCCESS = 'SUBSCRIPTION_FETCH_SUCCESS';
export const SUBSCRIPTION_FETCH_FAIL = 'SUBSCRIPTION_FETCH_FAIL';
export const ALREADY_PURCHASED_FETCH_BEGIN = 'ALREADY_PURCHASED_FETCH_BEGIN';
export const ALREADY_PURCHASED_FETCH_SUCCESS =
  'ALREADY_PURCHASED_FETCH_SUCCESS';
export const ALREADY_PURCHASED_FETCH_FAIL = 'ALREADY_PURCHASED_FETCH_FAIL';
export const CANCEL_SUBSCRIPTION_BEGIN = 'CANCEL_SUBSCRIPTION_BEGIN';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAIL = 'CANCEL_SUBSCRIPTION_FAIL';

export const FETCH_PRODUCTS_BEGIN = 'FETCH_PRODUCTS_BEGIN';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAIL = 'FETCH_PRODUCTS_FAIL';

// PaymentMethods
export const USER_PAYMENT_METHOD_FETCH_BEGIN = 'PAYMENT_METHOD_FETCH_BEGIN';
export const USER_PAYMENT_METHOD_FETCH_SUCCESS =
  'USER_PAYMENT_METHOD_FETCH_SUCCESS';
export const USER_PAYMENT_METHOD_FETCH_FAIL = 'USER_PAYMENT_METHOD_FETCH_FAIL';
export const ATTACH_PAYMENT_METHOD_BEGIN = 'ATTACH_PAYMENT_METHOD_BEGIN';
export const ATTACH_PAYMENT_METHOD_SUCCESS = 'ATTACH_PAYMENT_METHOD_SUCCESS';
export const ATTACH_PAYMENT_METHOD_FAIL = 'ATTACH_PAYMENT_METHOD_FAIL';
export const DETACH_PAYMENT_METHOD_BEGIN = 'DETACH_PAYMENT_METHOD_BEGIN';
export const DETACH_PAYMENT_METHOD_SUCCESS = 'DETACH_PAYMENT_METHOD_SUCCESS';
export const DETACH_PAYMENT_METHOD_FAIL = 'DETACH_PAYMENT_METHOD_FAIL';
export const SET_DEFAULT_PAYMENT_METHOD_BEING =
  'SET_DEFAULT_PAYMENT_METHOD_BEING';
export const SET_DEFAULT_PAYMENT_METHOD_SUCESS =
  'SET_DEFAULT_PAYMENT_METHOD_SUCESS';
export const SET_DEFAULT_PAYMENT_METHOD_FAIL =
  'SET_DEFAULT_PAYMENT_METHOD_FAIL';

//Pipelines
export const INSTANCE_PIPELINES_FETCH_BEGIN = 'INSTANCE_PIPELINES_FETCH_BEGIN';
export const INSTANCE_PIPELINES_FETCH_SUCCESS =
  'INSTANCE_PIPELINES_FETCH_SUCCESS';
export const INSTANCE_PIPELINES_FETCH_FAIL = 'INSTANCE_PIPELINES_FETCH_FAIL';
export const RESET_PIPELINE_DATA = 'RESET_PIPELINE_DATA';
export const ADD_INSTANCE_BEGIN = 'ADD_INSTANCE_BEGIN';
export const ADD_INSTANCE_SUCCESS = 'ADD_INSTANCE_SUCCESS';
export const ADD_INSTANCE_FAIL = 'ADD_INSTANCE_FAIL';
export const FETCH_INSTANCE_BEGIN = 'FETCH_INSTANCE_BEGIN';
export const FETCH_INSTANCE_SUCCESS = 'FETCH_INSTANCE_SUCCESS';
export const FETCH_INSTANCE_FAIL = 'FETCH_INSTANCE_FAIL';
export const EDIT_INSTANCE_BEGIN = 'EDIT_INSTANCE_BEGIN';
export const EDIT_INSTANCE_SUCCESS = 'EDIT_INSTANCE_BEGIN';
export const EDIT_INSTANCE_FAIL = 'EDIT_INSTANCE_BEGIN';
export const DELETE_INSTANCE_BEGIN = 'DELETE_INSTANCE_BEGIN';
export const DELETE_INSTANCE_SUCCESS = 'DELETE_INSTANCE_BEGIN';
export const DELETE_INSTANCE_FAIL = 'DELETE_INSTANCE_BEGIN';
export const RESET_PIPELINE_ERROR = 'RESET_PIPELINE_ERROR';
export const SET_NAME_SPACE = 'SET_NAME_SPACE';
export const SET_PIPELINE_TRANSFORM_DATA = 'SET_PIPELINE_TRANSFORM_DATA';
export const GET_NAMESPACES_BEGIN = 'GET_NAMESPACES_BEGIN';
export const GET_NAMESPACES_SUCCESS = 'GET_NAMESPACES_SUCCESS';
export const GET_NAMESPACES_FAIL = 'GET_NAMESPACES_FAIL';
export const SET_CHECKED_PIPELINES = 'SET_CHECKED_PIPELINES';
export const SET_INSTANCE_ID = 'SET_INSTANCE_ID';
export const EXPORT_PIPELINES_BEGIN = 'EXPORT_PIPELINES_BEGIN';
export const EXPORT_PIPELINES_FAIL = 'EXPORT_PIPELINES_FAIL';
export const EXPORT_PIPELINES_SUCCESS = 'EXPORT_PIPELINES_SUCCESS';
export const EXPORT_ALL_PIPELINES_BEGIN = 'EXPORT_ALL_PIPELINES_BEGIN';
export const EXPORT_ALL_PIPELINES_FAIL = 'EXPORT_ALL_PIPELINES_FAIL';
export const EXPORT_ALL_PIPELINES_SUCCESS = 'EXPORT_ALL_PIPELINES_SUCCESS';
export const FILTER_PIPELINES = 'FILTER_PIPELINES';
export const HANDLE_EXPAND = 'HANDLE_EXPAND';
export const DELETE_PIPELINES_BEGIN = 'DELETE_PIPELINES_BEGIN';
export const DELETE_PIPELINES_FAIL = 'DELETE_PIPELINES_FAIL';
export const DELETE_PIPELINES_SUCCESS = 'DELETE_PIPELINES_SUCCESS';
export const START_PIPELINES_BEGIN = 'START_PIPELINES_BEGIN';
export const START_PIPELINES_FAIL = 'START_PIPELINES_FAIL';
export const START_PIPELINES_SUCCESS = 'START_PIPELINES_SUCCESS';
export const CLEAR_ACTION_RESPONSE = 'CLEAR_ACTION_RESPONSE';
export const DENSE_PADDING = 'DENSE_PADDING';
export const UPLOAD_PIPELINE_BEGIN = 'UPLOAD_PIPELINE_BEGIN';
export const UPLOAD_PIPELINE_SUCCESS = 'UPLOAD_PIPELINE_SUCCESS';
export const UPLOAD_PIPELINE_FAIL = 'UPLOAD_PIPELINE_FAIL';
export const GIT_INSTANCE_EXPAND_TREE = 'GIT_INSTANCE_EXPAND_TREE';
export const COMMIT_PIPELINE_UPLOAD_BEGIN = 'COMMIT_PIPELINE_UPLOAD_BEGIN';
export const COMMIT_PIPELINE_UPLOAD_FAIL = 'COMMIT_PIPELINE_UPLOAD_FAIL';
export const COMMIT_PIPELINE_UPLOAD_SUCCESS = 'COMMIT_PIPELINE_UPLOAD_SUCCESS';
export const COMMIT_PIPELINE_DELETE_BEGIN = 'COMMIT_PIPELINE_DELETE_BEGIN';
export const COMMIT_PIPELINE_DELETE_FAIL = 'COMMIT_PIPELINE_DELETE_FAIL';
export const COMMIT_PIPELINE_DELETE_SUCCESS = 'COMMIT_PIPELINE_DELETE_SUCCESS';
export const SET_COMMITED_DATA = 'SET_COMMITED_DATA';
export const FETCH_JSON_TREE_BEGIN = 'FETCH_JSON_TREE_BEGIN';
export const FETCH_JSON_TREE_SUCCESS = 'FETCH_JSON_TREE_SUCCESS';
export const FETCH_JSON_TREE_FAIL = 'FETCH_JSON_TREE_FAIL';
export const CLONE_REPO_BEGIN = 'CLONE_REPO_BEGIN';
export const CLONE_REPO_SUCCESS = 'CLONE_REPO_SUCCESS';
export const CLONE_REPO_FAIL = 'CLONE_REPO_FAIL';
export const RESET_GIT_ITEM_DATA = 'RESET_GIT_ITEM_DATA';
export const GIT_EXPORT_BEGIN = 'GIT_EXPORT_BEGIN';
export const GIT_EXPORT_SUCCESS = 'GIT_EXPORT_SUCCESS';
export const GIT_EXPORT_FAIL = 'GIT_EXPORT_FAIL';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const UPDATE_GIT_INSTANCE_BEGIN = 'UPDATE_GIT_INSTANCE_BEGIN';
export const UPDATE_GIT_INSTANCE_SUCCESS = 'UPDATE_GIT_INSTANCE_SUCCESS';
export const UPDATE_GIT_INSTANCE_FAIL = 'UPDATE_GIT_INSTANCE_FAIL';
export const GET_QUEUE_TASKS_SUCCESS = 'GET_QUEUE_TASKS_SUCCESS';
export const GET_QUEUE_TASKS_FAIL = 'GET_QUEUE_TASKS_FAIL';

//TransferPipelines
export const INDIVIDUAL_PIPELINE_BEGIN = 'INDIVIDUAL_PIPELINE_BEGIN';
export const INDIVIDUAL_PIPELINE_SUCCESS = 'INDIVIDUAL_PIPELINE_SUCCESS';
export const INDIVIDUAL_PIPELINE_FAIL = 'INDIVIDUAL_PIPELINE_FAIL';
export const COMPARE_INSTANCE_BEGIN = 'COMPARE_INSTANCE_BEGIN';
export const COMPARE_INSTANCE_SUCCESS = 'COMPARE_INSTANCE_SUCCESS';
export const COMPARE_INSTANCE_FAIL = 'COMPARE_INSTANCE_FAIL';
export const DEPLOY_PIPELINE_BEGIN = 'DEPLOY_PIPELINE_BEGIN';
export const DEPLOY_PIPELINE_SUCCESS = 'DEPLOY_PIPELINE_SUCCESS';
export const DEPLOY_PIPELINE_FAIL = 'DEPLOY_PIPELINE_FAIL';
export const DEPLOY_PLUGIN_BEGIN = 'DEPLOY_PLUGIN_BEGIN';
export const DEPLOY_PLUGIN_SUCCESS = 'DEPLOY_PLUGIN_SUCCESS';
export const DEPLOY_PLUGIN_FAIL = 'DEPLOY_PLUGIN_FAIL';
export const CLEAR_DEPLOY_RESPONSE = 'CLEAR_DEPLOY_RESPONSE';
export const GET_TARGET_NAMESPACES_BEGIN = 'GET_TARGET_NAMESPACES_BEGIN';
export const GET_TARGET_NAMESPACES_SUCCESS = 'GET_TARGET_NAMESPACES_SUCCESS';
export const GET_TARGET_NAMESPACES_FAIL = 'GET_TARGET_NAMESPACES_FAIL';
export const CLEAR_COMPARE_DATA = 'CLEAR_COMPARE_DATA';
export const CLEAR_TARGET_NAMESPACES = 'CLEAR_TARGET_NAMESPACES';
export const CLEAR_INDIVIDUAL_PIPELINE = 'CLEAR_INDIVIDUAL_PIPELINE';
export const BATCH_TRANSFER_BEGIN = 'BATCH_TRANSFER_BEGIN';
export const BATCH_TRANSFER_SUCCESS = 'BATCH_TRANSFER_SUCCESS';
export const BATCH_TRANSFER_FAIL = 'BATCH_TRANSFER_FAIL';
export const SAVE_DRAFT_BEGIN = 'SAVE_DRAFT_BEGIN';
export const SAVE_DRAFT_FAIL = 'SAVE_DRAFT_FAIL';
export const SAVE_DRAFT_SUCCESS = 'SAVE_DRAFT_SUCCESS';
export const CHECK_BATCH_TRANSFER_BEGIN = 'CHECK_BATCH_TRANSFER_BEGIN';
export const CHECK_BATCH_TRANSFER_SUCCESS = 'CHECK_BATCH_TRANSFER_SUCCESS';
export const CHECK_BATCH_TRANSFER_FAIL = 'CHECK_BATCH_TRANSFER_FAIL';
export const CLEAR_CHECK_DEPLOING_RESPONSE = 'CLEAR_CHECK_DEPLOING_RESPONSE';
export const SET_TARGET_INSTANCE_ID_AND_NS = 'SET_TARGET_INSTANCE_ID_AND_NS';
export const SET_SAVE_TO_DRAFTS = 'SET_SAVE_TO_DRAFTS';
export const GIT_TRANSFER_BEGIN = 'GIT_TRANSFER_BEGIN';
export const GIT_TRANSFER_SUCCESS = 'GIT_TRANSFER_SUCCESS';
export const GIT_TRANSFER_FAIL = 'GIT_TRANSFER_FAIL';
