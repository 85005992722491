import React, { Component } from 'react';
import { withRouter } from '../../hooks';
import { connect } from 'react-redux';
import { details } from '../../actions';
import openCube from '../../assets/images/openCube.png';
import ProEDMS from '../../assets/images/ProEDMS.png';
import upload from '../../assets/images/upload.jpg';
import chain from '../../assets/images/chain.png';

class WidgetLinks extends Component {
  goToParent = (artifact) => {
    this.props.history.push(`/details/${artifact.name}/${artifact.version}/`);
    this.props.setWidgetIndex(-1);
  };

  render() {
    const { artifact } = this.props;
    const artifactIcon = artifact.imageUrl;
    return (
      <div className="WidgetLinks" style={{ marginLeft: '15px' }}>
        <div className="row">
          <img src={openCube} alt="" style={{ marginRight: '10px' }} />
          <b style={{ marginTop: '7px' }}>Component of → </b>
          <img
            style={{ marginLeft: '10px', width: '30px', height: '30px' }}
            src={artifactIcon || ProEDMS}
            alt=""
          />
          <p style={{ marginLeft: '7px', marginTop: '7px' }}>
            {artifact.converted_name}
          </p>
        </div>
        {/* <ol></ol> */}
        <div className="row">
          <button
            className="btn btn-md"
            onClick={() => this.goToParent(artifact)}
            style={{
              background: 'none',
              border: '1px solid #d1d1d1',
              cursor: 'pointer',
              marginTop: '30px',
            }}
          >
            <img
              src={upload}
              alt=""
              width="15%"
              height="auto"
              style={{ marginTop: '7px', float: 'left' }}
            />
            <img
              src={chain}
              alt=""
              width="20"
              height="20"
              style={{ background: 'none' }}
            />
            <label
              style={{
                marginTop: '14px',
                marginLeft: '7px',
                cursor: 'pointer',
              }}
            >
              {artifact.name}
            </label>
          </button>
        </div>
      </div>
    );
  }
}

WidgetLinks.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  data: state.details.data,
});

const mapActionsToProps = {
  setWidgetIndex: details.setWidgetIndex,
};

export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(WidgetLinks)
);
