const API_HOST =
  window.location.hostname === 'localhost'
    ? 'http://localhost:8000/'
    : `${window.location.origin}/`;

const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;

const properties = {
  API_HOST,
  STRIPE_API_KEY,
};
export default properties;
