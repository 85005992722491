import React, { Component } from 'react';
import { connect } from 'react-redux';
import ShoppingCartHeader from '../../components/ShoppingCartHeader';
import { set as setIn } from 'lodash';
import { filter } from 'lodash';
import PluginItemSource from '../../components/PluginItemSource';
import PluginItemTarget from '../../components/PluginItemTarget';
import { pipelines, transferPipelines } from '../../actions';
import LoadingPopup from '../../components/LoadingPopup';
import ResponsePopup from '../../components/ResponsePopup';
import LoadingSpiner from '../../components/LoadingSpinner';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { withRouter } from '../../hooks';

class TransferPipelines extends Component {
  state = {
    targetInstance: {
      instance: '',
      // this.props.userInstances[0] && this.props.userInstances[0]['uniqueId'],
      namespace: '',
    },
    instance: this.props.instance_id,
    namespace: this.props.nameSpace,
    saveToDrafts: false,
    pipeline: '',
  };

  changeHandler = (event) => {
    const newState = setIn(this.state, event.target.name, event.target.value);
    this.setState({
      ...newState,
    });
  };

  saveToDraftsToggel = () => {
    this.setState({
      saveToDrafts: !this.state.saveToDrafts,
    });
  };

  componentDidMount = async () => {
    await this.props.clearTargetNamespaces();
    await this.props.clearIndividualPipeline();
  };

  changeInstanceEvent = async (event) => {
    const inputName = event.target.name;
    this.changeHandler(event);
    if (inputName === 'instance') {
      await this.props.resetPipelineData();
      await this.props.getNamespaces(
        this.state.instance
        // this.props.currentUser.accessToken
      );
      if (this.props.sourceInstanceNamespaces !== null) {
        setIn(
          this.state,
          'namespace',
          this.props.sourceInstanceNamespaces.namespaces[0]['name']
        );
      }
    }
    await this.props.fetchInstancePipelines(
      this.state.instance,
      this.state.namespace
      // this.props.currentUser.accessToken
    );
  };

  changeTargetInstanceEvent = async (event) => {
    const inputName = event.target.name;
    await this.changeHandler(event);
    await this.props.clearTargetNamespaces();
    await this.props.getTargetInstanceNamespaces(
      event.target.value
      // this.props.currentUser.accessToken
    );
    if (inputName === 'targetInstance.instance') {
      if (this.props.targetInstanceNamespaces !== null) {
        setIn(
          this.state,
          'targetInstance.namespace',
          this.props.targetInstanceNamespaces.namespaces[0]['name']
        );
        this.setInstanceTocompare();
      }
    }
  };

  setInstanceTocompare = async () => {
    const { individualPipelineData } = this.props;
    const { targetInstance } = this.state;
    if (individualPipelineData !== null) {
      const obj = {
        targetInstance: targetInstance.instance,
        namespace: targetInstance.namespace,
        pipelineConfig: individualPipelineData.pipelineConfig,
      };
      await this.props.compareInstance(
        // this.props.currentUser.uid,
        obj
        // this.props.currentUser.accessToken
      );
    }
    return;
  };

  renderTrgetInstancePluginItems = () => {
    const { compareInstanceData, individualPipelineData } = this.props;
    if (compareInstanceData.length > 0 && individualPipelineData !== null) {
      const pluginItems = compareInstanceData.map((el, index) => {
        return <PluginItemTarget key={index} itemData={el} />;
      });
      return pluginItems;
    }
    return;
  };

  renderSourcePluginItems = () => {
    const { individualPipelineData } = this.props;
    if (individualPipelineData !== null) {
      const pluginItems = individualPipelineData.pipelineConfig.map(
        (el, index) => {
          return <PluginItemSource key={index} itemData={el} />;
        }
      );
      return pluginItems;
    }
    return;
  };

  deployPlugin = async (data) => {
    const obj = {
      artifact: data.artifact,
      version: data.version,
      instance: this.state.targetInstance.instance,
      namespace: this.state.targetInstance.namespace,
    };
    await this.props.deployPlugin(obj);
    await this.setInstanceTocompare();
  };

  renderOpTypeItems = () => {
    const { compareInstanceData, individualPipelineData } = this.props;
    if (compareInstanceData.length > 0 && individualPipelineData !== null) {
      const opTypeItems = compareInstanceData.map((el, index) => {
        return (
          <div key={index}>
            {el.opType === 'Install' ? (
              <div
                style={{ marginBottom: '45px' }}
                className="btn btn-primary"
                onClick={() => {
                  this.deployPlugin(el);
                }}
              >
                Install
              </div>
            ) : el.opType === 'No Solution' ? (
              <div className="op-type" style={{ background: '#e45064' }}>
                <p style={{ padding: '6px', fontWeight: '500' }}>{el.opType}</p>
              </div>
            ) : (
              <div className="op-type">
                <p style={{ padding: '6px', fontWeight: '500' }}>{el.opType}</p>
              </div>
            )}
          </div>
        );
      });
      return opTypeItems;
    }
    return;
  };
  transferPipeline = async () => {
    const { individualPipelineData, compareInstanceData } = this.props;
    // const uid = Object.keys(individualPipelineData.exportedJSON)[0];

    individualPipelineData.exportedJSON['artifact'] = filter(
      compareInstanceData,
      { type: 'artifact' }
    )[0]['artifact'];

    individualPipelineData.exportedJSON['config']['stages'].forEach((stage) => {
      let replacedArtifact = filter(compareInstanceData, {
        name: stage.plugin.name,
      })[0];
      stage.plugin.artifact = replacedArtifact && replacedArtifact['artifact'];
    });

    let data = {};
    if (this.state.saveToDrafts) {
      data['config'] = individualPipelineData.exportedJSON;
      await this.props.saveDraft(
        this.state.targetInstance.instance,
        this.state.targetInstance.namespace,
        data
        // this.props.currentUser.accessToken
      );
      return;
    }
    let exportedJSON = individualPipelineData.exportedJSON;
    data['config'] = {
      name: exportedJSON.name,
      artifact: exportedJSON.artifact,
      description: exportedJSON.description,
      config: exportedJSON.config,
    };
    await this.props.deployPipeline(
      this.state.targetInstance.instance,
      this.state.targetInstance.namespace,
      data
      // this.props.currentUser.accessToken
    );
    return;
  };

  render() {
    const {
      individualPipelineData,
      compareInstanceData,
      deployingResponse,
      namespaceError,
      targetNamespacesError,
    } = this.props;
    return (
      <div className="container py-4">
        {this.props.isLoadingDeploing && (
          <LoadingPopup textToShow="Loading..." />
        )}
        {deployingResponse !== null && (
          <ResponsePopup
            history={this.props.history}
            textToShow={deployingResponse.message}
          />
        )}
        <div className="col-xl-12 col-lg-12">
          <div className="card-stats mb-4 mb-xl-0 pd-10">
            <div className="card-body bg-white rounder-borders card-body-shadow">
              <ShoppingCartHeader
                goBack={() => {
                  this.props.history.goBack();
                }}
                history={this.props.history}
                componentName="Transfer Pipelines"
              />
              <div className="row">
                <div className="col-lg-12">
                  <div className="card-body">
                    <div className="col-lg-12 mgTopBottom border-top pd-10">
                      <div className="row" style={{ marginTop: '15px' }}>
                        <div className="col bg-color-shoppingList rounder-borders">
                          <div style={{ margin: '15px' }}>
                            <div
                              className="col-sm-12"
                              style={{ marginTop: '15px' }}
                            >
                              <p style={{ marginBottom: '15px' }}>
                                Source Instance
                              </p>
                              <TextField
                                error={namespaceError !== null}
                                select
                                name="instance"
                                label="Instance"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                value={this.state.instance}
                                onChange={(event) => {
                                  this.changeInstanceEvent(event);
                                }}
                              >
                                {this.props.userInstances.map(
                                  (element, index) =>
                                    element.type !== 'GIT' && (
                                      <MenuItem
                                        key={index}
                                        value={element.uniqueId}
                                      >
                                        {namespaceError !== null &&
                                        namespaceError['instance_id'] ===
                                          element.uniqueId ? (
                                          <div>
                                            {element.instanceName}&nbsp;
                                            <Tooltip
                                              title={namespaceError['message']}
                                            >
                                              <i
                                                className="fa fa-exclamation-triangle"
                                                style={{
                                                  color: '#ef6262',
                                                }}
                                              />
                                            </Tooltip>
                                          </div>
                                        ) : (
                                          element.instanceName
                                        )}
                                      </MenuItem>
                                    )
                                )}
                              </TextField>
                            </div>
                            <div
                              className="col-sm-12"
                              style={{ marginTop: '15px' }}
                            >
                              <TextField
                                disabled={
                                  this.props.sourceInstanceNamespaces === null
                                }
                                variant="outlined"
                                name="namespace"
                                select
                                label="Namespace"
                                color="primary"
                                fullWidth
                                value={this.state.namespace}
                                onChange={(event) =>
                                  this.changeInstanceEvent(event)
                                }
                              >
                                {this.props.sourceInstanceNamespaces &&
                                  this.props.sourceInstanceNamespaces.namespaces.map(
                                    (element, index) => (
                                      <MenuItem
                                        key={index}
                                        value={element.name}
                                      >
                                        {element.name}
                                      </MenuItem>
                                    )
                                  )}
                              </TextField>
                            </div>
                            <div
                              className="col-sm-12"
                              style={{ marginTop: '15px' }}
                            >
                              <TextField
                                disabled={
                                  this.props.sourceInstanceNamespaces === null
                                }
                                select
                                name="pipeline"
                                variant="outlined"
                                label="Pipeline"
                                color="primary"
                                fullWidth
                                value={this.state.pipeline}
                                onChange={async (event) => {
                                  this.changeHandler(event);
                                  await this.props.individualPipeline(
                                    this.state.instance,
                                    this.state.namespace,
                                    this.state.pipeline
                                    // this.props.currentUser.accessToken,
                                    // true
                                  );
                                  this.props.clearCompareData();
                                  if (
                                    this.state.targetInstance.namespace !== ''
                                  ) {
                                    await this.setInstanceTocompare();
                                  }
                                }}
                              >
                                {this.props.instanceData &&
                                  this.props.instanceData.pipelines.map(
                                    (element, index) => (
                                      <MenuItem
                                        key={index}
                                        value={element.name}
                                      >
                                        {element.name}
                                      </MenuItem>
                                    )
                                  )}
                              </TextField>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-1 text-center"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <i className="fa fa-arrow-right"></i>
                        </div>
                        <div className="col bg-color-shoppingList rounder-borders">
                          <div style={{ margin: '15px' }}>
                            <div
                              className="col-sm-12"
                              style={{ marginTop: '15px' }}
                            >
                              <p style={{ marginBottom: '15px' }}>
                                Target Instance
                              </p>
                              <TextField
                                error={targetNamespacesError !== null}
                                select
                                name="targetInstance.instance"
                                label="Instance"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                value={this.state.targetInstance.instance}
                                onChange={(event) =>
                                  this.changeTargetInstanceEvent(event)
                                }
                              >
                                {this.props.userInstances.map(
                                  (element, index) => (
                                    <MenuItem
                                      key={index}
                                      value={element.uniqueId}
                                    >
                                      {targetNamespacesError !== null &&
                                      targetNamespacesError['instance_id'] ===
                                        element.uniqueId ? (
                                        <div>
                                          {element.instanceName}&nbsp;
                                          <Tooltip
                                            title={
                                              targetNamespacesError['message']
                                            }
                                          >
                                            <i
                                              className="fa fa-exclamation-triangle"
                                              style={{ color: '#ef6262' }}
                                            />
                                          </Tooltip>
                                        </div>
                                      ) : (
                                        element.instanceName
                                      )}
                                    </MenuItem>
                                  )
                                )}
                              </TextField>
                            </div>
                            <div
                              className="col-sm-12"
                              style={{ marginTop: '15px' }}
                            >
                              <TextField
                                select
                                disabled={
                                  this.props.targetInstanceNamespaces === null
                                }
                                name="targetInstance.namespace"
                                label="Namespace"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                value={this.state.targetInstance.namespace}
                                onChange={async (event) => {
                                  this.changeHandler(event);
                                  await this.setInstanceTocompare();
                                }}
                              >
                                {this.props.targetInstanceNamespaces !== null &&
                                  this.props.targetInstanceNamespaces.namespaces.map(
                                    (element, index) => (
                                      <MenuItem
                                        key={index}
                                        value={element.name}
                                      >
                                        {element.name}
                                      </MenuItem>
                                    )
                                  )}
                              </TextField>
                            </div>
                            <div
                              className="col-lg-12"
                              style={{ paddingTop: '18px' }}
                            >
                              <label
                                className={
                                  this.state.saveToDrafts === false
                                    ? 'a-switch-pdRight font-bold'
                                    : 'a-switch-pdRight'
                                }
                              >
                                Deploy
                              </label>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={() => {
                                    this.saveToDraftsToggel();
                                  }}
                                  checked={this.state.saveToDrafts}
                                />
                                <span className="slider round graybg"></span>
                              </label>
                              <label
                                className={
                                  this.state.saveToDrafts === true
                                    ? 'a-switch-pdLeft font-bold'
                                    : 'a-switch-pdLeft'
                                }
                              >
                                Save as Draft
                              </label>
                            </div>
                            <div
                              className="text-center"
                              style={{
                                margin: '10px',
                                marginTop: '53px',
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.props.isLoadingTargetInstance ? (
                <div className="col-lg-12 bg-color-shoppingList rounder-borders">
                  <div style={{ marginBottom: '10px' }}>
                    <LoadingSpiner textToShow="Loading..." />
                  </div>
                </div>
              ) : (
                <div>
                  {individualPipelineData !== null && (
                    <div className="col-lg-12 bg-color-shoppingList rounder-borders">
                      <div className="row">
                        <div className="col-source-plugins text-center">
                          <p style={{ fontWeight: 'bold' }}>
                            Source Version:{' '}
                            {individualPipelineData.artifactVersion}
                          </p>
                          {this.renderSourcePluginItems()}
                        </div>
                        <div className="col-op-plugins text-center">
                          {compareInstanceData.length > 0 && (
                            <div>
                              <p className="font-bold">Op Type</p>
                              <div style={{ marginTop: '25px' }}>
                                {this.renderOpTypeItems()}
                              </div>
                            </div>
                          )}
                        </div>
                        {compareInstanceData.length > 0 ? (
                          <div className="col-target-plugins text-center">
                            <p style={{ fontWeight: 'bold' }}>
                              Target Version:{' '}
                              {compareInstanceData[0]['version']}
                            </p>
                            {this.renderTrgetInstancePluginItems()}
                          </div>
                        ) : (
                          <div className="col-target-plugins text-center">
                            <p className="font-bold">
                              Set your target instance where you want to
                              transfer the pipeline.
                            </p>
                          </div>
                        )}
                      </div>
                      {compareInstanceData.length > 0 && (
                        <div
                          className="col-lg-12 text-center"
                          style={{ marginBottom: '15px' }}
                        >
                          <div
                            className="btn btn-primary"
                            onClick={() => {
                              this.transferPipeline();
                            }}
                          >
                            Transfer Pipeline
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  isLoadingTargetInstance: state.transferPipelines.isLoading,
  isLoadingDeploing: state.transferPipelines.isLoadingDeploing,
  userInstances: state.pipelines.userInstances,
  instance_id: state.pipelines.instance_id,
  nameSpace: state.pipelines.nameSpace,
  namespaceError: state.pipelines.namespaceError,
  targetNamespacesError: state.transferPipelines.targetNamespacesError,
  instanceData: state.pipelines.data,
  targetInstaceData: state.transferPipelines.targetInstaceData,
  individualPipelineData: state.transferPipelines.individualPipelineData,
  compareInstanceData: state.transferPipelines.compareInstanceData,
  deployingResponse: state.transferPipelines.deployingResponse,
  sourceInstanceNamespaces: state.pipelines.instanceNamespaces,
  targetInstanceNamespaces: state.transferPipelines.targetInstanceNamespaces,
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  ...ownProps,
});

const mapActionsToProps = {
  fetchInstancePipelines: pipelines.fetchInstancePipelines,
  individualPipeline: transferPipelines.individualPipeline,
  getNamespaces: pipelines.getNamespaces,
  getTargetInstanceNamespaces: transferPipelines.getTargetInstanceNamespaces,
  compareInstance: transferPipelines.compareInstance,
  deployPipeline: transferPipelines.deployPipeline,
  deployPlugin: transferPipelines.deployPlugin,
  saveDraft: transferPipelines.saveDraft,
  clearCompareData: transferPipelines.clearCompareData,
  clearIndividualPipeline: transferPipelines.clearIndividualPipeline,
  resetPipelineData: pipelines.resetPipelineData,
  clearTargetNamespaces: transferPipelines.clearTargetNamespaces,
};
export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(TransferPipelines)
);
