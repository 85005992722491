// import * as actionTypes from "../actions/types";

const initialActiveState = {
  activeSearchReducer: 'oss',
  activeViewType: window.innerWidth < 991 ? 'list' : 'card',
  activeTab: 'all',
  activeTabPipeline: 'plugins',
};

const activeState = (state = initialActiveState, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_REDUCER': {
      return {
        ...state,
        activeSearchReducer: action.payload.activeReducer,
      };
    }
    case 'SET_ATIVE_VIEW_TYPE': {
      return {
        ...state,
        activeViewType: action.payload.activeViewType,
      };
    }
    case 'SET_ACTIVE_TAB': {
      console.debug('SET_ACTIVE_TAB', action);
      return {
        ...state,
        activeTab: action.payload.activeTab,
      };
    }
    case 'SET_ACTIVE_TAB_PIPELINE': {
      console.debug('SET_ACTIVE_TAB_PIPELINE', action);
      return {
        ...state,
        activeTabPipeline: action.payload.activeTab,
      };
    }
    default:
      return state;
  }
};
export default activeState;