import React, { Component } from 'react';
import Tooltip from '@mui/material/Tooltip';

class PluginItemTarget extends Component {
  render() {
    const { itemData } = this.props;
    return (
      <div className="row" style={{ justifyContent: 'flex-end' }}>
        <div className="col-lg-9" style={{ padding: '0' }}>
          {itemData.opType !== 'Install' ? (
            <Tooltip title={`${itemData.name} | ${itemData.artifact.version}`}>
              <div className="contenair-Plugin">
                <ul
                  className="plugin-specs row d-inline-block text-truncate"
                  style={{ display: 'inline-table', width: '280px' }}
                >
                  <li>{itemData.name}</li>
                  <li>|</li>
                  <li>v {itemData.artifact.version}</li>
                </ul>
              </div>
            </Tooltip>
          ) : (
            <div
              className="contenair-Plugin"
              style={{ background: 'transparent', border: '0' }}
            >
              <p
                className="plugin-specs"
                style={{
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  paddingBottom: '13px',
                }}
              >
                This plugin was not found
              </p>
            </div>
          )}
        </div>
        <div className="col-lg-2 merge-plugin-line">________</div>
      </div>
    );
  }
}

export default PluginItemTarget;
