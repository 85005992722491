import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ShoppingCartHeader, Plans } from '../../components';
import { withRouter } from '../../hooks';

class PricingDetails extends Component {
  render() {
    return (
      <div className=" container py-4">
        <div className="col-xl-12 col-lg-12">
          <div className="card-stats mb-4 mb-xl-0 pd-xl-10">
            <div className="card-body bg-white rounder-borders card-body-shadow">
              <ShoppingCartHeader
                goBack={() => {
                  this.props.history.goBack();
                }}
                componentName="Pricing Plans"
              />
              <div className="card-body">
                <div className="border-top text-center">
                  <Plans history={this.props.history} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  currentPlan: state.user.currentPlan,
  // ...ownProps,
});
export default withRouter(connect(mapStateToProps)(PricingDetails));
