import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { userActions } from '../../actions';
import { connect } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { withRouter } from '../../hooks';
import { Properties, Constants } from '../../config';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: [],
      loading: false,
      showPassword: false,
    };
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  isFormValid = ({ email, password }) => {
    let errors = [];
    let error;
    if (this.isFormEmpty(this.state)) {
      error = { message: 'Fill in all fields!' };
      this.setState({ errors: errors.concat(error) });
      return false;
    }
    return email && password;
  };

  isFormEmpty = ({ email, password }) => {
    return !email.length || !password.length;
  };

  displayError = (errors) => {
    return errors.map((errors, i) => <i key={i}>{errors.message}</i>);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.props.setErrorToNull();
    if (this.isFormValid(this.state)) {
      this.setState({ errors: [], loading: true });
      await this.props.regularSignIn(this.state.email, this.state.password);
      if (this.props.error) {
        this.setState({
          errors: this.state.errors.concat({
            message: 'Invalid credentils or somethong went wrong',
          }),
          loading: false,
        });
        return;
      }
      const redirect_url = localStorage.getItem(Constants.REDIRECR_AFTER_URL);
      localStorage.removeItem(Constants.REDIRECR_AFTER_URL);
      if (redirect_url) {
        this.props.history.push(redirect_url);
      } else {
        this.props.history.push('/');
      }
    }
  };

  render() {
    const { email, password } = this.state;

    return (
      <div
        style={{
          background: `linear-gradient(135deg, #172B4D, #31496D, #172B4D, #31496D, #172B4D)`,
          backgroundSize: `cover`,
          minHeight: '100vh',
          padding: '16px',
        }}
      >
        <div className="auth-header">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-6 text-center">
              <img
                src="https://d33wubrfki0l68.cloudfront.net/23317a66889fb01027aeb64af443fce2c03623b4/74d74/adaptivescale/img/logo.png"
                alt=""
                style={{
                  width: '150px',
                  padding: '0px 0px 10px 0px',
                  cursor: 'pointer',
                }}
                onClick={() => this.props.router.navigate('/')}
              />
              <div style={{ marginBottom: '20px' }}>
                <center>
                  <p className="text-white" style={{ fontSize: '25px' }}>
                    Data Integration Cloud
                  </p>
                </center>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            background: '#f8f9fe',
            width: '100%',
            maxWidth: '400px',
            borderRadius: '4px',
            margin: '0px auto',
          }}
        >
          <div className="p-4">
            <div className="">
              <button className="btn-no-bg">
                <i
                  className="fa fa-arrow-left button-click"
                  onClick={() => this.props.router.navigate('/')}
                  style={{ color: 'gray', cursor: 'pointer' }}
                />
              </button>
            </div>
            <div className="">
              <div className="text-muted text-lg text-center mb-2">
                <small> Sign in with</small>
              </div>
              <div
                className="text-center"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '10px 0',
                }}
              >
                <a
                  href={`${Properties.API_HOST}auth/google/login/`}
                  className="btn btn-neutral btn-icon"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '8px',
                    width: '150px',
                    padding: '8px',
                  }}
                >
                  <div className="">
                    <img
                      src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                      alt=""
                    />
                  </div>
                  <div style={{ fontSize: '18px', color: 'black' }}>Google</div>
                </a>
              </div>
            </div>
            <div className="">
              <div className="text-center text-muted mb-4">
                <small>Or Sign in with credentials</small>
              </div>
              <form onSubmit={(event) => this.handleSubmit(event)}>
                <div className="form-group mb-3">
                  <FormControl
                    className="input-group bg-white"
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Email
                    </InputLabel>
                    <OutlinedInput
                      name="email"
                      label="Email"
                      type="email"
                      value={email}
                      onChange={(event) => this.handleChange(event)}
                      id="standard-start-adornment"
                      required
                    />
                  </FormControl>
                </div>
                <div className="form-group mb-3">
                  <FormControl
                    className="input-group bg-white"
                    variant="outlined"
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      value={password}
                      name="password"
                      label="Password"
                      type={this.state.showPassword ? 'text' : 'password'}
                      onChange={(event) => this.handleChange(event)}
                      id="standard-start-adornment"
                      required
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              this.handleClickShowPassword();
                            }}
                            edge="end"
                          >
                            {this.state.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
                {this.state.errors.length > 0 && (
                  <div className="login-error">
                    <p
                      className="inline-block"
                      style={{
                        fontSize: '15px',
                        color: '#c14343',
                        overflowWrap: 'break-word',
                      }}
                    >
                      {this.displayError(this.state.errors)}
                    </p>
                  </div>
                )}
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-primary my-4"
                    style={{
                      width: '150px',
                      fontSize: '18px',
                    }}
                    disabled={this.state.loading}
                  >
                    {this.props.isLoading ? 'Loading...' : 'Sign In'}
                  </button>
                </div>
              </form>
              <div className="row my-3">
                <div className="col-12 text-center pb-4">
                  You are not registered?{' '}
                  <Link to="/register" className="text-center">
                    Sign up.
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateFromProps = (state) => ({
  isLoading: state.user.isLoading,
  error: state.user.error,
});

const mapActionsToProps = {
  setUser: userActions.setUser,
  regularSignIn: userActions.regularSignIn,
  setErrorToNull: userActions.setErrorToNull,
};

export default withRouter(connect(mapStateFromProps, mapActionsToProps)(Login));
