import * as actionTypes from '../actions/types';

const initialPipelinesState = {
  error: null,
  namespaceError: null,
  data: null,
  userInstances: [],
  // checkedPipelines: [],
  instanceNamespaces: null,
  instance_id: '',
  nameSpace: '',
  actionsResponse: null,
  attachResponse: null,
  isLoading: false,
  isLoadingExport: false,
  isLoadingFetchInstance: false,
  densePadding: false,
  queueTasks: [],
};

const pipelines = (state = initialPipelinesState, action) => {
  switch (action.type) {
    case actionTypes.INSTANCE_PIPELINES_FETCH_BEGIN:
      console.debug(actionTypes.INSTANCE_PIPELINES_FETCH_BEGIN);
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.INSTANCE_PIPELINES_FETCH_SUCCESS:
      console.debug(actionTypes.INSTANCE_PIPELINES_FETCH_SUCCESS);
      let index = state.userInstances.findIndex(
        (instance) => instance.uniqueId === action.payload.data.uniqueId
      );
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        ...(state.userInstances[index]['currentNamespace'] =
          action.payload.namespace),
        ...(state.userInstances[index]['pipelines'] =
          action.payload.data.pipelines),
        ...(state.userInstances[index]['expand'] = true),
        ...(state.userInstances[index]['filteredData'] =
          action.payload.data.pipelines),
        ...(state.userInstances[index]['checkedPipelines'] = []),
        userInstances: [...state.userInstances],
      };
    case actionTypes.FILTER_PIPELINES:
      let fileredData = state.userInstances[action.payload.index][
        'pipelines'
      ].filter((pipeline) =>
        pipeline['name']
          .toLowerCase()
          .includes(action.payload.keyword.toLowerCase())
      );
      return {
        ...state,
        ...(state.userInstances[action.payload.index]['filteredData'] =
          fileredData),
        userInstances: state.userInstances.map((el) => {
          return { ...el };
        }),
      };
    case actionTypes.INSTANCE_PIPELINES_FETCH_FAIL:
      console.debug(actionTypes.INSTANCE_PIPELINES_FETCH_FAIL);
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actionTypes.RESET_PIPELINE_DATA:
      console.debug(actionTypes.RESET_PIPELINE_DATA);
      return {
        ...state,
        instanceNamespaces: null,
        nameSpace: '',
        data: null,
      };
    case actionTypes.ADD_INSTANCE_BEGIN:
      console.debug(actionTypes.ADD_INSTANCE_BEGIN);
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.ADD_INSTANCE_SUCCESS:
      console.debug(actionTypes.ADD_INSTANCE_SUCCESS);
      return {
        ...state,
        isLoading: false,
        attachResponse: action.payload.response,
      };
    case actionTypes.ADD_INSTANCE_FAIL:
      console.debug(actionTypes.ADD_INSTANCE_FAIL);
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actionTypes.FETCH_INSTANCE_BEGIN:
      console.debug(actionTypes.FETCH_INSTANCE_BEGIN);
      return {
        ...state,
        isLoadingFetchInstance: true,
      };
    case actionTypes.FETCH_INSTANCE_SUCCESS:
      console.debug(actionTypes.FETCH_INSTANCE_SUCCESS);
      return {
        ...state,
        isLoadingFetchInstance: false,
        isLoading: false,
        error: null,
        userInstances: action.payload.data.map((el) => {
          return {
            ...el,
          };
        }),
      };
    case actionTypes.FETCH_INSTANCE_FAIL:
      console.debug(actionTypes.FETCH_INSTANCE_FAIL);
      return {
        ...state,
        isLoadingFetchInstance: false,
        error: action.payload.error,
      };
    case actionTypes.EDIT_INSTANCE_BEGIN:
      console.debug(actionTypes.EDIT_INSTANCE_BEGIN);
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.EDIT_INSTANCE_SUCCESS:
      console.debug(actionTypes.EDIT_INSTANCE_SUCCESS);
      return {
        ...state,
        isLoading: false,
        attachResponse: action.payload.response,
      };
    case actionTypes.EDIT_INSTANCE_FAIL:
      console.debug(actionTypes.EDIT_INSTANCE_FAIL);
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actionTypes.DELETE_INSTANCE_BEGIN:
      console.debug(actionTypes.DELETE_INSTANCE_BEGIN);
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.DELETE_INSTANCE_SUCCESS:
      console.debug(actionTypes.DELETE_INSTANCE_SUCCESS);
      return {
        ...state,
        isLoading: false,
        attachResponse: action.payload.response,
      };
    case actionTypes.DELETE_INSTANCE_FAIL:
      console.debug(actionTypes.DELETE_INSTANCE_FAIL);
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actionTypes.RESET_PIPELINE_ERROR:
      console.debug(actionTypes.DELETE_INSTANCE_FAIL);
      return {
        ...state,
        namespaceError: null,
      };
    case actionTypes.SET_NAME_SPACE:
      console.debug(actionTypes.SET_NAME_SPACE);
      return {
        ...state,
        nameSpace: action.payload,
      };
    case actionTypes.GET_NAMESPACES_BEGIN:
      console.debug(actionTypes.GET_NAMESPACES_BEGIN);
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_NAMESPACES_SUCCESS:
      console.debug(actionTypes.GET_NAMESPACES_SUCCESS);
      let foundIndex = state.userInstances.findIndex(
        (instance) => instance.uniqueId === action.payload.data.uniqueId
      );
      return {
        ...state,
        isLoading: false,
        instanceNamespaces: action.payload.data,
        namespaceError: null,
        ...(state.userInstances[foundIndex]['namespacesData'] =
          action.payload.data),
        userInstances: [...state.userInstances],
      };
    case actionTypes.GET_NAMESPACES_FAIL:
      console.debug(actionTypes.GET_NAMESPACES_FAIL);
      return {
        ...state,
        isLoading: false,
        namespaceError: action.payload.error,
      };
    case actionTypes.SET_CHECKED_PIPELINES:
      console.debug(actionTypes.SET_CHECKED_PIPELINES);
      state.userInstances[action.payload.index]['checkedPipelines'] =
        action.payload.data;
      return {
        ...state,
        userInstances: state.userInstances.map((el) => {
          return {
            ...el,
          };
        }),
      };
    case actionTypes.SET_INSTANCE_ID:
      console.debug(actionTypes.SET_INSTANCE_ID);
      return {
        ...state,
        instance_id: action.payload,
      };
    case actionTypes.EXPORT_PIPELINES_BEGIN:
      console.debug(actionTypes.EXPORT_PIPELINES_BEGIN);
      return {
        ...state,
        isLoadingExport: true,
      };
    case actionTypes.EXPORT_PIPELINES_SUCCESS:
      console.debug(actionTypes.EXPORT_PIPELINES_SUCCESS);
      return {
        ...state,
        isLoadingExport: false,
        // instanceNamespaces: action.payload.data,
      };
    case actionTypes.EXPORT_PIPELINES_FAIL:
      console.debug(actionTypes.EXPORT_PIPELINES_FAIL);
      return {
        ...state,
        isLoadingExport: false,
        namespaceError: action.payload.error.message,
      };
    case actionTypes.EXPORT_ALL_PIPELINES_BEGIN:
      console.debug(actionTypes.EXPORT_ALL_PIPELINES_BEGIN);
      return {
        ...state,
        isLoadingExport: true,
      };
    case actionTypes.EXPORT_ALL_PIPELINES_SUCCESS:
      console.debug(actionTypes.EXPORT_ALL_PIPELINES_SUCCESS);
      return {
        ...state,
        isLoadingExport: false,
      };
    case actionTypes.EXPORT_ALL_PIPELINES_FAIL:
      console.debug(actionTypes.EXPORT_ALL_PIPELINES_FAIL);
      return {
        ...state,
        isLoadingExport: false,
        namespaceError: action.payload.error.message,
      };
    case actionTypes.DELETE_PIPELINES_BEGIN:
      console.debug(actionTypes.DELETE_PIPELINES_BEGIN);
      return {
        ...state,
        isLoadingExport: true,
        actionsResponse: null,
      };
    case actionTypes.DELETE_PIPELINES_SUCCESS:
      console.debug(actionTypes.DELETE_PIPELINES_SUCCESS);
      return {
        ...state,
        isLoadingExport: false,
        actionsResponse: action.payload.response,
        // instanceNamespaces: action.payload.data,
      };
    case actionTypes.DELETE_PIPELINES_FAIL:
      console.debug(actionTypes.DELETE_PIPELINES_FAIL);
      return {
        ...state,
        isLoadingExport: false,
        actionsResponse: null,
        namespaceError: action.payload.error.message,
      };
    case actionTypes.START_PIPELINES_BEGIN:
      console.debug(actionTypes.START_PIPELINES_BEGIN);
      return {
        ...state,
        isLoadingExport: true,
        actionsResponse: null,
      };
    case actionTypes.START_PIPELINES_SUCCESS:
      console.debug(actionTypes.START_PIPELINES_SUCCESS);
      return {
        ...state,
        isLoadingExport: false,
        actionsResponse: action.payload.response,
        // instanceNamespaces: action.payload.data,
      };
    case actionTypes.START_PIPELINES_FAIL:
      console.debug(actionTypes.START_PIPELINES_FAIL);
      return {
        ...state,
        isLoadingExport: false,
        actionsResponse: null,
        namespaceError: action.payload.error.message,
      };
    case actionTypes.UPLOAD_PIPELINE_BEGIN:
      console.debug(actionTypes.UPLOAD_PIPELINE_BEGIN);
      return {
        ...state,
        isLoadingExport: true,
        actionsResponse: null,
      };
    case actionTypes.UPLOAD_PIPELINE_SUCCESS:
      console.debug(actionTypes.UPLOAD_PIPELINE_SUCCESS);
      return {
        ...state,
        isLoadingExport: false,
        actionsResponse: action.payload.response,
      };
    case actionTypes.UPLOAD_PIPELINE_FAIL:
      console.debug(actionTypes.UPLOAD_PIPELINE_FAIL);
      return {
        ...state,
        isLoadingExport: false,
        actionsResponse: null,
        namespaceError: action.payload.error.message,
      };
    case actionTypes.COMMIT_PIPELINE_DELETE_BEGIN:
      console.debug(actionTypes.COMMIT_PIPELINE_DELETE_BEGIN);
      return {
        ...state,
        isLoadingExport: true,
        actionsResponse: null,
        namespaceError: null,
      };
    case actionTypes.COMMIT_PIPELINE_DELETE_SUCCESS:
      console.debug(actionTypes.COMMIT_PIPELINE_DELETE_SUCCESS);
      return {
        ...state,
        isLoadingExport: false,
        actionsResponse: action.payload.response,
      };
    case actionTypes.COMMIT_PIPELINE_DELETE_FAIL:
      console.debug(actionTypes.COMMIT_PIPELINE_DELETE_FAIL);
      return {
        ...state,
        isLoadingExport: false,
        actionsResponse: null,
        namespaceError: action.payload.error.message,
      };

    case actionTypes.COMMIT_PIPELINE_UPLOAD_BEGIN:
      console.debug(actionTypes.COMMIT_PIPELINE_UPLOAD_BEGIN);
      return {
        ...state,
        isLoadingExport: true,
        actionsResponse: null,
        namespaceError: null,
      };
    case actionTypes.COMMIT_PIPELINE_UPLOAD_SUCCESS:
      console.debug(actionTypes.COMMIT_PIPELINE_UPLOAD_SUCCESS);
      return {
        ...state,
        isLoadingExport: false,
        actionsResponse: action.payload.response,
      };
    case actionTypes.COMMIT_PIPELINE_UPLOAD_FAIL:
      console.debug(actionTypes.COMMIT_PIPELINE_UPLOAD_FAIL);
      return {
        ...state,
        isLoadingExport: false,
        actionsResponse: null,
        namespaceError: action.payload.error.message,
      };

    case actionTypes.SET_COMMITED_DATA:
      console.debug(actionTypes.SET_COMMITED_DATA);
      state.userInstances[action.payload.index]['commitedData'] =
        action.payload.data;
      return {
        ...state,
        userInstances: state.userInstances.map((el) => {
          return {
            ...el,
          };
        }),
      };
    case actionTypes.CLEAR_ACTION_RESPONSE:
      console.debug(actionTypes.CLEAR_ACTION_RESPONSE);
      return {
        ...state,
        actionsResponse: null,
      };
    case actionTypes.HANDLE_EXPAND:
      console.debug(actionTypes.HANDLE_EXPAND);
      state.userInstances[action.payload.index]['expand'] =
        !state.userInstances[action.payload.index]['expand'];
      return {
        ...state,
        userInstances: state.userInstances.map((el) => {
          return { ...el };
        }),
      };
    case actionTypes.DENSE_PADDING:
      console.debug(actionTypes.DENSE_PADDING);
      return {
        ...state,
        densePadding: !state.densePadding,
      };
    case actionTypes.GIT_INSTANCE_EXPAND_TREE:
      console.debug(actionTypes.GIT_INSTANCE_EXPAND_TREE);
      state.userInstances[action.payload.index]['expandTree'] =
        action.payload.data;
      return {
        ...state,
        userInstances: state.userInstances.map((el) => {
          return { ...el };
        }),
      };
    case actionTypes.FETCH_JSON_TREE_BEGIN:
      console.debug(actionTypes.FETCH_JSON_TREE_BEGIN);
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.FETCH_JSON_TREE_SUCCESS:
      console.debug(actionTypes.FETCH_JSON_TREE_SUCCESS);
      return {
        ...state,
        ...(state.userInstances[action.payload.index]['json_tree'] =
          action.payload.data),
        userInstances: [...state.userInstances],
        isLoading: false,
      };
    case actionTypes.FETCH_JSON_TREE_FAIL:
      console.debug(actionTypes.FETCH_JSON_TREE_FAIL);
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actionTypes.RESET_GIT_ITEM_DATA:
      console.debug(actionTypes.RESET_GIT_ITEM_DATA);
      return {
        ...state,
        ...(state.userInstances[action.payload.index]['checkedPipelines'] = []),
        ...(state.userInstances[action.payload.index]['expandTree'] = []),
        userInstances: [...state.userInstances],
      };
    case actionTypes.CLONE_REPO_BEGIN:
      console.debug(actionTypes.CLONE_REPO_BEGIN);
      return {
        ...state,
        isLoading: true,
        // isLoadingFetchInstance: true,
      };
    case actionTypes.CLONE_REPO_SUCCESS:
      console.debug(actionTypes.CLONE_REPO_SUCCESS);
      return {
        ...state,
        isLoading: false,
        isLoadingFetchInstance: false,
      };
    case actionTypes.CLONE_REPO_FAIL:
      console.debug(actionTypes.CLONE_REPO_FAIL);
      return {
        ...state,
        isLoading: false,
        isLoadingFetchInstance: false,
        error: action.payload.error,
      };
    case actionTypes.GIT_EXPORT_BEGIN:
      console.debug(actionTypes.GIT_EXPORT_BEGIN);
      return {
        ...state,
        isLoadingExport: true,
      };
    case actionTypes.GIT_EXPORT_SUCCESS:
      console.debug(actionTypes.GIT_EXPORT_SUCCESS);
      return {
        ...state,
        isLoadingExport: false,
        actionsResponse: action.payload.response,
      };
    case actionTypes.GIT_EXPORT_FAIL:
      console.debug(actionTypes.GIT_EXPORT_FAIL);
      return {
        ...state,
        isLoadingExport: false,
        namespaceError: action.payload.error.message,
      };
    case actionTypes.SET_ERROR_MESSAGE:
      console.debug(actionTypes.GIT_EXPORT_FAIL);
      return {
        ...state,
        isLoadingExport: false,
        namespaceError: action.payload.error,
      };
    case actionTypes.UPDATE_GIT_INSTANCE_BEGIN:
      console.debug(actionTypes.UPDATE_GIT_INSTANCE_BEGIN);
      return {
        ...state,
        // isLoading: true,
      };
    case actionTypes.UPDATE_GIT_INSTANCE_SUCCESS:
      console.debug(actionTypes.UPDATE_GIT_INSTANCE_SUCCESS);
      const instanceData = action.payload.instanceData;
      const instanceFromApi = action.payload.response.find(
        (instance) => instance.instanceName === instanceData.instanceName
      );

      if (!instanceFromApi) {
        return {
          ...state,
          userInstances: action.payload.response,
          isLoading: false,
        };
      }

      const instanceFromApiKeys = Object.keys(instanceFromApi).filter(
        (key) => key !== 'expand'
      );

      const newInstance = {};
      Object.keys(instanceData).forEach((key) => {
        if (instanceFromApiKeys.includes(key)) {
          newInstance[key] = instanceFromApi[key];
        } else {
          newInstance[key] = instanceData[key];
        }
      });

      let tmpInstances = [...state.userInstances];
      tmpInstances[action.payload.index] = {
        ...newInstance,
      };
      return {
        ...state,
        userInstances: tmpInstances,
        isLoading: false,
      };
    case actionTypes.UPDATE_GIT_INSTANCE_FAIL:
      console.debug(actionTypes.UPDATE_GIT_INSTANCE_FAIL);
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actionTypes.GET_QUEUE_TASKS_SUCCESS:
      console.debug(actionTypes.GET_QUEUE_TASKS_SUCCESS);
      return {
        ...state,
        queueTasks: action.payload.response,
      };
    case actionTypes.GET_QUEUE_TASKS_FAIL:
      console.debug(actionTypes.GET_QUEUE_TASKS_FAIL);
      return {
        ...state,
        queueTasks: [],
      };
    default:
      return state;
  }
};

export default pipelines;
