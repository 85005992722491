import React, { Component } from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';

class EnhancedTableHead extends Component {
  headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
    {
      id: 'description',
      numeric: true,
      disablePadding: false,
      label: 'Description',
    },
    { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
  ];

  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={
                this.props.numSelected > 0 &&
                this.props.numSelected < this.props.rowCount
              }
              checked={
                this.props.rowCount > 0 &&
                this.props.numSelected === this.props.rowCount
              }
              onChange={this.props.onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
          {this.headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={
                this.props.orderBy === headCell.id ? this.props.order : false
              }
            >
              <TableSortLabel
                active={this.props.orderBy === headCell.id}
                direction={
                  this.props.orderBy === headCell.id ? this.props.order : 'asc'
                }
                onClick={this.createSortHandler(headCell.id)}
              >
                {headCell.label}
                {this.props.orderBy === headCell.id ? (
                  <span className={this.props.classes.visuallyHidden}>
                    {this.props.order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

export default EnhancedTableHead;
