import React, { Component } from 'react';
import { connect } from 'react-redux';
import { set as setIn } from 'lodash';
import { pipelines } from '../../actions';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

class CommitMessage extends Component {
  state = {
    commitMessage: '',
    targetBranch: '',
    namespace: '',
    branch: this.props.instanceData.currentBranch,
  };

  changeHandler = (event) => {
    const newState = setIn(this.state, event.target.name, event.target.value);
    this.setState({
      ...newState,
    });
  };

  handleSubmit = () => {
    const { instanceData } = this.props;
    if (!this.commitForm.checkValidity()) {
      this.commitForm.reportValidity();
      return;
    }
    if (this.props.actionType === 'delete') {
      const data = {
        checked_pipelines: instanceData.checkedPipelines,
        data: this.state,
      };
      this.props.commitPipelineDelete(
        // currentUser.uid,
        // currentUser.accessToken,
        instanceData.uniqueId,
        data,
        this.props.instanceIndex
      );
      this.props.resetGitItemData(this.props.instanceIndex);
      this.props.deleteHandler();
      return;
    }
    if (this.props.actionType === 'upload') {
      const dataToStore = {
        pipeline_files: this.props.instanceData.commitedData,
        data: this.state,
      };
      this.props.commitPipelineUpload(
        // currentUser.uid,
        // currentUser.accessToken,
        instanceData.uniqueId,
        dataToStore
        // this.props.instanceIndex
      );
      this.props.resetGitItemData(this.props.instanceIndex);
      this.props.uploadHandler();
    }
  };

  render() {
    const { instanceData } = this.props;
    return (
      <div className="popup">
        <div className="inerPopup col-lg-3 text-center">
          <p style={{ fontWeight: 'bold' }}>
            Commit to save changes in remote.
          </p>
          <form name="commitForm" ref={(form) => (this.commitForm = form)}>
            <div className="col-sm-12">
              <textarea
                onFocus={(e) => (e.target.placeholder = '')}
                onChange={(event) => {
                  this.changeHandler(event);
                }}
                onBlur={(e) => (e.target.placeholder = 'Service Account JSON')}
                placeholder="Commit Message"
                required
                name="commitMessage"
                className="text-area-curl"
                style={{ fontSize: '14px', marginTop: '25px' }}
              />
            </div>
            {/* <div className="col-sm-12" style={{ marginTop: '15px' }}>
              <TextField
                variant="outlined"
                name="targetBranch"
                label="Set target Branch"
                color="primary"
                fullWidth
                onChange={this.changeHandler}
                required
              />
            </div> */}
            {this.props.actionType === 'upload' && (
              <div className="col-sm-12" style={{ marginTop: '15px' }}>
                <TextField
                  variant="outlined"
                  name="namespace"
                  select
                  label="Namespace"
                  color="primary"
                  fullWidth
                  value={this.state.namespace}
                  onChange={this.changeHandler}
                  required
                >
                  {instanceData.json_tree.children.map((element, index) => (
                    <MenuItem key={index} value={element.label}>
                      {element.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            )}
            <div
              className="row"
              style={{ justifyContent: 'center', marginTop: '20px' }}
            >
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.handleSubmit();
                }}
              >
                Commit changes
              </button>
              <button
                className="btn btn-light"
                onClick={() => {
                  this.props.actionType === 'delete'
                    ? this.props.deleteHandler()
                    : this.props.uploadHandler();
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
});
const mapActionsToProps = {
  commitPipelineUpload: pipelines.commitPipelineUpload,
  commitPipelineDelete: pipelines.commitPipelineDelete,
  resetGitItemData: pipelines.resetGitItemData,
};
export default connect(mapStateToProps, mapActionsToProps)(CommitMessage);
