import './index.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-tagsinput/react-tagsinput.css';
import './assets/fonts/font.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers/reducers';
import { Provider, connect } from 'react-redux';
import thunk from 'redux-thunk';
import { userActions } from './actions';
import App from './App';

const store = createStore(rootReducer, applyMiddleware(thunk));

class Root extends React.Component {
  componentDidMount = () => {
    this.props.fetchUserProfile();
  };

  render() {
    return <App />;
  }
}

const mapActionsToProps = {
  fetchUserProfile: userActions.fetchUserProfile,
};

const RootWithProps = connect(() => ({}), mapActionsToProps)(Root);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <Router>
      <RootWithProps />
    </Router>
  </Provider>
);
