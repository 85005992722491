import React, { Component } from 'react';
import { Properties } from '../../config';
import { stripPluginSuffixPrefix } from '../../helpers';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { withRouter } from '../../hooks';
import openCube from '../../assets/images/openCube.png';
import ProEDMS from '../../assets/images/ProEDMS.png';

class WidgetDescription extends Component {
  versionsHandler = (event, widgetStrippedName) => {
    const index = this.props.data.findIndex(
      (artifact) => artifact.version === event.target.value
    );
    this.props.setSelectedArtifactIndex(index);
    this.props.history.push(
      `/details/${this.props.data[index].name}/${this.props.data[index].version}/${widgetStrippedName.toLowerCase()}`
    );
  };

  render() {
    const { artifact, widget, versions } = this.props;
    const widgetStrippedName = stripPluginSuffixPrefix(this.props.widget.name);
    let imgSrc = widget.icon_link
      ? widget.icon_link
      : `${Properties.API_HOST}packages/${artifact.name}/${artifact.version}/icon.png`;
    return (
      <div className="row">
        <div className="col-lg-2 text-center">
          <img
            className="card-img-top-list"
            src={imgSrc}
            alt="Card cap"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = ProEDMS;
            }}
          />
        </div>
        <div className="col-lg-10">
          <div className="">
            <div className="row">
              <h4 className="plugin-name-details">{widgetStrippedName}</h4>
              <div className="smallText-13 smallText-13-div">
                <img className="pull-right" src={openCube} alt="" />
                <div className="row">
                  <label
                    style={{ marginTop: '10px' }}
                    className="fa fa-code-fork fa-2x"
                  />
                  <div className="mgLeft10">
                    <TextField
                      variant="outlined"
                      id="standard-select-version"
                      select
                      label="Version"
                      color="primary"
                      fullWidth
                      value={artifact.version}
                      onChange={(event) =>
                        this.versionsHandler(event, widgetStrippedName)
                      }
                    >
                      {versions.map((element, index) => (
                        <MenuItem key={element} value={element}>
                          {element}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                {/* <label className="fa fa-code-fork fa-2x"/>
                                <label className="mgLeft10">
                                    <select className="col-lg-12" onChange={(event) => this.versionsHandler(event, widgetStrippedName)}
                                            value={artifact.version}>
                                        {versions.map((element, index) =>
                                            <option key={element} value={element}>{element}</option>)}
                                    </select>
                                </label> */}
              </div>
            </div>
            <ol />
            <p>{artifact.description}</p>
          </div>
        </div>
      </div>
    );
  }
}

WidgetDescription.propTypes = {};

export default withRouter(WidgetDescription);
