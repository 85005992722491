import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pipelines, transferPipelines } from '../../actions';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

class TransferPopup extends Component {
  state = {
    targetInstance: this.props.userInstances[0]['uniqueId'],
    targetNamespace: null,
    saveToDrafts: false,
    targetInstance_index: null,
    targetInstance_type: null,
    targetInstance_id: null,
    branch: null,
  };

  // componentDidMount = async () => {
  //   await this.props.getTargetInstanceNamespaces(
  //     this.props.userInstances[0]['uniqueId'],
  //     this.props.currentUser.accessToken
  //   );
  //   if (this.props.targetInstanceNamespaces !== null) {
  //     await this.props.setTargetInstanceIdandNs(
  //       this.props.userInstances[0]['uniqueId'],
  //       this.props.targetInstanceNamespaces.namespaces[0]['name']
  //     );
  //     this.setState({
  //       targetNamespace: this.props.targetInstanceNamespaces.namespaces[0][
  //         'name'
  //       ],
  //     });
  //   }
  // };

  saveToDraftsToggel = () => {
    this.props.setSaveToDrafts();
    this.setState({
      saveToDrafts: !this.state.saveToDrafts,
    });
  };

  setTargetInstance = async (event, key) => {
    await this.setState({
      targetInstance: event.target.value.uniqueId,
      targetInstance_id: event.target.value.uniqueId,
      targetInstance_type: event.target.value.type,
      targetInstance_index: Number(key.key.replace('.$', '')),
    });
    const { targetInstance_id, targetInstance_type, targetInstance_index } =
      this.state;
    if (targetInstance_type === 'GIT') {
      await this.props.clearTargetNamespaces();
      this.setState({ targetNamespace: null });
      await this.props.setInstanceId(targetInstance_id);
      await this.props.fetchJSONTree(
        event.target.value.uniqueId,
        targetInstance_index
      );
      return;
    }

    await this.props.clearTargetNamespaces();
    this.setState({ targetNamespace: null });
    await this.props.setInstanceId(targetInstance_id);
    await this.props.getTargetInstanceNamespaces(
      targetInstance_id
      // this.props.currentUser.accessToken
    );
    if (this.props.targetInstanceNamespaces !== null) {
      await this.props.setTargetInstanceIdandNs(
        targetInstance_id,
        this.props.targetInstanceNamespaces.namespaces[0]['name'],
        targetInstance_type
      );
      this.setState({
        targetInstance: targetInstance_id,
        targetNamespace:
          this.props.targetInstanceNamespaces.namespaces[0]['name'],
      });
    }
    return;
  };

  transferPipelines = async () => {
    if (
      this.state.targetNamespace !== null &&
      this.state.targetInstance !== null
    ) {
      if (this.props.actionType === 'pipeline-transfer') {
        let data = {
          branch: this.state.branch,
          sourceInstance: this.props.instanceData.uniqueId,
          sourceNamespace: this.props.instanceData.currentNamespace,
          targetInstance: this.state.targetInstance,
          targetInstanceNamespace: this.state.targetNamespace,
          targetInstanceType: this.state.targetInstance_type,
          pipelinesList: this.props.instanceData.checkedPipelines,
          save_to_drafts: this.props.saveToDrafts,
        };
        this.props.transferPopupHandler();
        if (this.state.targetInstance_type === 'GIT') {
          await this.props.batchTransfer(data);
          await this.props.setInstanceId(data.targetInstance);
        } else {
          await this.props.checkBatchTransfer(data, this.props.instanceIndex);
          await this.props.setInstanceId(data.targetInstance);
        }
      } else {
        let data = {
          targetInstance: this.state.targetInstance,
          targetInstanceNamespace: this.state.targetNamespace,
          pipelinesList: this.props.instanceData.checkedPipelines,
          save_to_drafts: this.props.saveToDrafts,
          branch: this.props.instanceData.currentBranch,
        };
        this.props.transferPopupHandler();

        await this.props.gitTransferPipelines(
          // this.props.currentUser.uid,
          this.props.instanceData.uniqueId,
          // this.props.currentUser.accessToken,
          data
          // this.props.instanceIndex
        );
        await this.props.setInstanceId(data.targetInstance);
      }
    }
    return;
  };

  render() {
    const { targetNamespacesError, userInstances } = this.props;
    return (
      <div className="popup">
        <div className="inerPopup col-lg-3">
          <p style={{ fontWeight: 'bold', marginTop: '10px' }}>
            Set the instance where you want to transfer the pipelines.
          </p>
          {this.state.targetInstance_type !== 'GIT' && (
            <div className="col-lg-12" style={{ paddingTop: '18px' }}>
              <div
                className="row justify-content-md-end"
                style={{ marginRight: '15px' }}
              >
                <label
                  className={
                    this.state.saveToDrafts === false
                      ? 'a-switch-pdRight font-bold'
                      : 'a-switch-pdRight'
                  }
                >
                  Deploy
                </label>
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={() => {
                      this.saveToDraftsToggel();
                    }}
                    checked={this.state.saveToDrafts}
                  />
                  <span className="slider round graybg"></span>
                </label>
                <label
                  className={
                    this.state.saveToDrafts === true
                      ? 'a-switch-pdLeft font-bold'
                      : 'a-switch-pdLeft'
                  }
                >
                  Save as Draft
                </label>
              </div>
            </div>
          )}
          <div className="col-sm-12" style={{ marginTop: '15px' }}>
            <TextField
              select
              error={targetNamespacesError !== null}
              name="targetInstance.instance"
              label="Target Instaces"
              variant="outlined"
              color="primary"
              fullWidth
              // value={this.state.targetInstance}
              onChange={(event, key) => this.setTargetInstance(event, key)}
            >
              {userInstances.map((element, index) =>
                this.props.actionType === 'pipeline-transfer' ? (
                  <MenuItem key={index} value={element}>
                    {targetNamespacesError !== null &&
                    targetNamespacesError['instance_id'] ===
                      element.uniqueId ? (
                      <div>
                        {element.instanceName}&nbsp;
                        <Tooltip title={targetNamespacesError['message']}>
                          <i
                            className="fa fa-exclamation-triangle"
                            style={{ color: '#ef6262' }}
                          />
                        </Tooltip>
                      </div>
                    ) : (
                      element.instanceName
                    )}
                  </MenuItem>
                ) : (
                  element.type !== 'GIT' && (
                    <MenuItem key={index} value={element}>
                      {targetNamespacesError !== null &&
                      targetNamespacesError['instance_id'] ===
                        element.uniqueId ? (
                        <div>
                          {element.instanceName}&nbsp;
                          <Tooltip title={targetNamespacesError['message']}>
                            <i
                              className="fa fa-exclamation-triangle"
                              style={{ color: '#ef6262' }}
                            />
                          </Tooltip>
                        </div>
                      ) : (
                        element.instanceName
                      )}
                    </MenuItem>
                  )
                )
              )}
            </TextField>
          </div>
          {this.state.targetInstance_type === 'GIT' ? (
            <div>
              <div className="col-sm-12" style={{ marginTop: '15px' }}>
                <TextField
                  select
                  name="targetInstance.namespace"
                  label="Namespace"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  value={this.state.targetInstance.namespace}
                  onChange={async (event) => {
                    await this.props.setTargetInstanceIdandNs(
                      this.props.targetInstanceId,
                      event.target.value,
                      this.state.targetInstance_type
                    );
                    this.setState({ targetNamespace: event.target.value });
                  }}
                >
                  {userInstances[this.state.targetInstance_index] &&
                    userInstances[this.state.targetInstance_index].json_tree &&
                    userInstances[
                      this.state.targetInstance_index
                    ].json_tree.children.map((element, index) => (
                      <MenuItem key={index} value={element.label}>
                        {element.label}
                      </MenuItem>
                    ))}
                </TextField>
              </div>
              <div className="col-sm-12" style={{ marginTop: '15px' }}>
                <TextField
                  select
                  name="branch"
                  label="Branch"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  value={this.state.targetInstance.namespace}
                  onChange={async (event) => {
                    await this.setState({ branch: event.target.value });
                  }}
                >
                  {userInstances[this.state.targetInstance_index] &&
                    userInstances[this.state.targetInstance_index].branches.map(
                      (element, index) => (
                        <MenuItem key={index} value={element}>
                          {element}
                        </MenuItem>
                      )
                    )}
                </TextField>
              </div>
            </div>
          ) : (
            <div className="col-sm-12" style={{ marginTop: '15px' }}>
              <TextField
                select
                disabled={this.props.targetInstanceNamespaces === null}
                name="targetInstance.namespace"
                label="Namespace"
                variant="outlined"
                color="primary"
                fullWidth
                value={this.state.targetInstance.namespace}
                onChange={async (event) => {
                  await this.props.setTargetInstanceIdandNs(
                    this.props.targetInstanceId,
                    event.target.value,
                    this.state.targetInstance_type
                  );
                  this.setState({ targetNamespace: event.target.value });
                }}
              >
                {this.props.targetInstanceNamespaces !== null &&
                  this.props.targetInstanceNamespaces.namespaces.map(
                    (element, index) => (
                      <MenuItem key={index} value={element.name}>
                        {element.name}
                      </MenuItem>
                    )
                  )}
              </TextField>
            </div>
          )}
          <div
            className="row"
            style={{ justifyContent: 'center', marginTop: '20px' }}
          >
            <button
              className="btn btn-primary"
              onClick={() => {
                this.transferPipelines();
              }}
            >
              Transfer
            </button>
            <button
              className="btn btn-light"
              onClick={() => {
                this.props.transferPopupHandler();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInstances: state.pipelines.userInstances,
  targetInstanceNamespaces: state.transferPipelines.targetInstanceNamespaces,
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  targetNamespacesError: state.transferPipelines.targetNamespacesError,
  saveToDrafts: state.transferPipelines.saveToDrafts,
  targetInstanceId: state.transferPipelines.targetInstanceId,
  tartgetNamespace: state.transferPipelines.tartgetNamespace,
});

const mapActionsToProps = {
  getTargetInstanceNamespaces: transferPipelines.getTargetInstanceNamespaces,
  checkBatchTransfer: transferPipelines.checkBatchTransfer,
  clearTargetNamespaces: transferPipelines.clearTargetNamespaces,
  setTargetInstanceIdandNs: transferPipelines.setTargetInstanceIdandNs,
  setSaveToDrafts: transferPipelines.setSaveToDrafts,
  gitTransferPipelines: transferPipelines.gitTransferPipelines,
  fetchInstancePipelines: pipelines.fetchInstancePipelines,
  fetchJSONTree: pipelines.fetchJSONTree,
  setInstanceId: pipelines.setInstanceId,
  batchTransfer: transferPipelines.batchTransfer,
};

export default connect(mapStateToProps, mapActionsToProps)(TransferPopup);
