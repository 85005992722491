import React, { Component } from 'react';
import Tooltip from '@mui/material/Tooltip';
// import { connect } from 'react-redux';

class PluginItemSource extends Component {
  render() {
    const { itemData } = this.props;
    return (
      <div className="row">
        <div className="col-lg-2 merge-plugin-line">________</div>
        <div className="col-lg-9" style={{ padding: '0' }}>
          <Tooltip title={`${itemData.name} | ${itemData.artifact.version}`}>
            <div className="contenair-Plugin">
              <ul
                className="plugin-specs row d-inline-block text-truncate"
                style={{ display: 'inline-table', width: '280px' }}
              >
                <li>{itemData.name}</li>
                <li>|</li>
                <li>v {itemData.artifact.version}</li>
              </ul>
            </div>
          </Tooltip>
        </div>
      </div>
    );
  }
}

export default PluginItemSource;
