import * as actionTypes from './types';
import { Constants } from '../config';
import { client } from '../config/api';

export const setUser = (user) => {
  return {
    type: actionTypes.SET_USER,
    payload: {
      currentUser: user,
    },
  };
};

export const clearUser = () => {
  return {
    type: actionTypes.CLEAR_USER,
  };
};

export const loggedIn = (logedIn, user) => {
  return {
    type: actionTypes.LOGGED_IN,
    payload: {
      loggedIn: logedIn,
      currentUser: user,
      isUserChecked: true,
    },
  };
};

export const setUserState = (currentUser, authToken, currentPlan) => {
  return {
    type: actionTypes.SET_USER_STATE,
    payload: {
      currentUser,
      authToken,
      currentPlan,
    },
  };
};

export const setUserPlan = (currentPlan) => {
  return {
    type: actionTypes.SET_USER_PLAN,
    payload: {
      currentPlan,
    },
  };
};

export const singInBegin = () => {
  return {
    type: actionTypes.USER_SIGN_IN_BEGIN,
  };
};

export const signInSuccess = (currentUser) => {
  return {
    type: actionTypes.USER_SIGN_IN_SUCCESS,
    payload: {
      currentUser,
    },
  };
};

export const signInFail = (error) => {
  return {
    type: actionTypes.USER_SIGN_IN_FAIL,
    payload: {
      error,
    },
  };
};

export const setErrorToNull = () => {
  return {
    type: actionTypes.CLEAR_ERROR,
    payload: null,
  };
};

export const regularSignIn = (email, pass) => {
  return async (dispatch) => {
    dispatch(singInBegin());
    try {
      const user = await client.post('auth/login/', { email, password: pass });
      dispatch(fetchUserProfile());
      return user;
    } catch (error) {
      return dispatch(signInFail(error));
    }
  };
};

export const regularSignUp = (email, pass) => {
  return async (dispatch) => {
    dispatch(singInBegin());
    try {
      const user = await client.post('auth/signup/', { email, password: pass });
      dispatch(fetchUserProfile());
      return user;
    } catch (error) {
      return dispatch(signInFail(error));
    }
  };
};

export const setUserStateFail = (error) => {
  return {
    type: actionTypes.FETCH_USER_PROFILE_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchUserProfileSuccess = (userProfile) => {
  return {
    type: actionTypes.FETCH_USER_PROFILE_SUCCESS,
    payload: {
      userProfile,
    },
  };
};

export const signOut = (callback) => {
  return async (dispatch) => {
    localStorage.removeItem(Constants.ACCESS_TOKEN_KEY);
    localStorage.removeItem(Constants.REFRESH_TOKEN_KEY);
    dispatch({ type: actionTypes.USER_SIGN_OUT });
    callback();
  };
};

export const fetchUserProfile = () => {
  return async (dispatch) => {
    try {
      const userProfileResponse = await client.get('auth/profile/');
      const userProfile = userProfileResponse.data;
      dispatch(
        setUserState(userProfile, userProfile.authToken, userProfile.plan_name)
      );
      dispatch(fetchUserProfileSuccess(userProfile));
      return userProfile;
    } catch (error) {
      dispatch(setUserStateFail(error));
    }
  };
};
