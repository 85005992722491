import * as actionTypes from '../actions/types';
import { stripExtension } from '../helpers';

const initialMYPLUGINSState = {
  error: null,
  data: [],
  isLoading: false,
  activeComponent: 'myPlugins',
  secretKey: null,
  uploadPluginIsLoading: false,
  uploadedPluginResponse: null,
  uploadPluginPercentage: -1,
  modalIsOpen: false,
  modalMessage: '',
  editPluginPopup: false,
  deletePluginPopup: false,
  pluginToEdit: null,
  attachResponse: null,
};

function mapWidgetDocs(data) {
  return data.map((artifact) => {
    if (artifact.widgets) {
      artifact.widgets = artifact.widgets.map((widget) => {
        const widgetDocIndex = artifact.docs_file
          ? artifact.docs_file.findIndex((doc) => {
              if (doc.name === undefined) {
                return false;
              }
              if (widget.name === undefined) {
                return false;
              }
              const tempDocFileName = stripExtension(doc.name).toLowerCase();
              const tempWidgetFileName = stripExtension(
                widget.name
              ).toLowerCase();
              return tempDocFileName === tempWidgetFileName;
            })
          : -1;
        widget['doc_file'] = null;
        if (widgetDocIndex !== -1) {
          widget['doc_file'] = artifact.docs_file[widgetDocIndex];
          widget['doc_file']['content'] = null;
        }
        return widget;
      });
    }
    return artifact;
  });
}

const myPlugins = (state = initialMYPLUGINSState, action) => {
  switch (action.type) {
    case 'CHANGE_MY_PLUGIN_STATE':
      console.info(actionTypes.CHANGE_MY_PLUGIN_STATE);
      return {
        ...state,
        activeComponent: action.payload.activeComponent,
      };
    case actionTypes.BEGIN_MYPLUGINS_FETCH:
      console.info(actionTypes.BEGIN_MYPLUGINS_FETCH);
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.MYPLUGINS_FETCH_SUCCESS:
      console.info(actionTypes.MYPLUGINS_FETCH_SUCCESS);
      const myPluginsData = mapWidgetDocs(action.payload.data);
      return {
        ...state,
        error: null,
        isLoading: false,
        data: myPluginsData,
      };
    case actionTypes.MYPLUGINS_FETCH_FAIL:
      console.info(actionTypes.MYPLUGINS_FETCH_FAIL);
      return {
        ...state,
        error: action.payload.error,
      };
    case actionTypes.UPLOAD_PLUGIN_BEGIN:
      console.info(actionTypes.UPLOAD_PLUGIN_BEGIN);
      return {
        ...state,
        uploadPluginIsLoading: true,
      };
    case actionTypes.UPLOAD_PLUGIN_SUCCESS:
      console.info(actionTypes.UPLOAD_PLUGIN_SUCCESS, action);
      return {
        ...state,
        error: null,
        uploadPluginIsLoading: false,
        uploadedPluginResponse: action.payload.data,
      };
    case actionTypes.UPLOAD_PLUGIN_FAIL:
      console.info(actionTypes.UPLOAD_PLUGIN_FAIL, action.payload.error);
      return {
        ...state,
        uploadPluginIsLoading: false,
        error: action.payload.error,
      };
    case actionTypes.SET_TOGGAL_MODAL:
      console.info(actionTypes.SET_TOGGAL_MODAL, action);
      return {
        ...state,
        modalIsOpen: !state.modalIsOpen,
        modalMessage: action.payload.alert,
      };
    case actionTypes.UPLOAD_PLUGIN_PERCENTAGE_UPDATE:
      console.debug(actionTypes.UPLOAD_PLUGIN_PERCENTAGE_UPDATE, action);
      return {
        ...state,
        uploadPluginPercentage: action.payload.percentage,
      };
    case actionTypes.EDIT_POPUP_HANDLER:
      console.debug(actionTypes.EDIT_POPUP_HANDLER, action);
      return {
        ...state,
        editPluginPopup: !state.editPluginPopup,
        pluginToEdit: action.payload,
      };
    case actionTypes.DELETE_POPUP_HANDLER:
      console.debug(actionTypes.DELETE_POPUP_HANDLER, action);
      return {
        ...state,
        deletePluginPopup: !state.deletePluginPopup,
        pluginToEdit: action.payload,
      };
    case actionTypes.EDIT_PLUGIN_BEGIN:
      console.debug(actionTypes.EDIT_PLUGIN_BEGIN, action);
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.EDIT_PLUGIN_SUCCESS:
      console.debug(actionTypes.EDIT_PLUGIN_SUCCESS, action);
      return {
        ...state,
        isLoading: false,
        attachResponse: action.payload.response,
      };
    case actionTypes.EDIT_PLUGIN_FAIL:
      console.debug(actionTypes.EDIT_PLUGIN_FAIL, action);
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actionTypes.DELETE_PLUGIN_BEGIN:
      console.debug(actionTypes.DELETE_PLUGIN_BEGIN, action);
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.DELETE_PLUGIN_SUCCESS:
      console.debug(actionTypes.DELETE_PLUGIN_SUCCESS, action);
      return {
        ...state,
        isLoading: false,
        attachResponse: action.payload.response,
      };
    case actionTypes.DELETE_PLUGIN_FAIL:
      console.debug(actionTypes.DELETE_PLUGIN_FAIL, action);
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
export default myPlugins;