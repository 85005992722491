import React, { Component } from 'react';
import { connect } from 'react-redux';
import { details } from '../../actions';

import { isEmpty } from 'lodash';
import ReactMarkdown from 'react-markdown';

class ArtifactDocumentation extends Component {
  componentDidMount = async () => {
    if (
      this.props.artifact.docs_file &&
      this.props.artifact.docs_file.length === 1 &&
      this.props.artifact.docs_file[0].link.startsWith('http')
    ) {
      await this.mdFileHandler(this.props.artifact.docs_file[0].link, 0);
    }
  };

  mdFileHandler = async (link, index) => {
    const { artifact } = this.props;
    if (!isEmpty(artifact.docs_file[index].content)) {
      this.props.setArtifactDocJson(artifact.docs_file[index].content, index);
      return;
    }
    const res = await fetch(link);
    const text = await res.text();
    this.props.setArtifactDocJson(text, index);
  };

  render() {
    const { artifact } = this.props;
    if (!artifact.docs_file) {
      return (
        <div style={{ marginTop: '20px' }}>
          <div>This plugin does not contain any documentation</div>
        </div>
      );
    }
    return (
      <div style={{ marginTop: '20px' }}>
        {artifact.docs_file &&
          artifact.docs_file.length > 1 &&
          artifact.docs_file.map((element, index) => (
            <p
              className="p-blue"
              key={index}
              onClick={() => this.mdFileHandler(element.link, index)}
              style={{ cursor: 'pointer', alignSelf: 'center' }}
            >
              {element.name}
            </p>
          ))}
        {!isEmpty(artifact.docs_file[this.props.activeMDIndex].content) && (
          <div>
            <ol></ol>
            <ReactMarkdown>
              {artifact.docs_file[this.props.activeMDIndex].content}
            </ReactMarkdown>
          </div>
        )}
        {artifact.hasDocumentation &&
          isEmpty(artifact.docs_file[this.props.activeMDIndex].content) && (
            <div>Loading...</div>
          )}
        {artifact.hasDocumentation === false &&
          artifact.docs_file.length === 1 && (
            <div>{artifact.docs_file[0].link}</div>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  activeMDIndex: state.details.activeMDIndex,
});

const mapActionsToProps = {
  setArtifactDocJson: details.setArtifactDocJson,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ArtifactDocumentation);
