import React, { Component } from 'react';
import { connect } from 'react-redux';

class LoadingPopup extends Component {
  render() {
    return (
      <div className="popup">
        <div className="inerPopup-loading col-lg-3 text-center">
          <div className="spinner-border" />
          <p style={{ fontWeight: 'bold', marginTop: '10px' }}>
            {this.props.textToShow}
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  activeComponent: state.myPlugins.activeComponent,
});
export default connect(mapStateToProps)(LoadingPopup);
