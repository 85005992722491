import {
  DETAILS_FETCH_FAIL,
  BEGIN_DETAILS_FETCH,
  JSON_FETCH_SUCCESS,
  SET_DETAILS_ARTIFACT,
  RESET_DETAILS_DATA,
  SET_DETAILS_SELECTED_INDEX,
  SET_DETAILS_ARTIFACT_TAB,
  SET_DETAILS_ARTIFACT_SUB_TAB,
  SET_DETAILS_ARTIFACT_JSON_FILE_CONTENT,
  SET_DETAILS_ARTIFACT_DOC,
  CLEAR_DETAILS,
  SET_DETAILS_WIDGET_DOC,
  SET_DETAILS_WIDGET_INDEX,
  SET_WIDGET_NAME,
} from './types';
import { Properties } from '../config';
import { client } from '../config/api';

const beginFetchDetails = () => ({
  type: BEGIN_DETAILS_FETCH,
});

const onFetchJSONSuccess = (json) => ({
  type: JSON_FETCH_SUCCESS,
  payload: { json },
});

const onFetchDetailsFail = (error) => ({
  type: DETAILS_FETCH_FAIL,
  payload: { error },
});

export const fetchJsonFile = (name, version, jsonPlugin) => {
  return async (dispatch) => {
    try {
      dispatch(beginFetchDetails());
      // const res = await fetch(
      //   `${Properties.API_HOST}packages/${name}/${version}/${jsonPlugin}`
      // );
      // const json = await res.json();
      const res = await client.get(`packages/${name}/${version}/${jsonPlugin}`);
      dispatch(onFetchJSONSuccess(res.data));
      // return json;
      return res.data;
    } catch (error) {
      dispatch(onFetchDetailsFail(error));
    }
  };
};

export const fetchJSONFileContent = (artifact, currentUser, userProfile) => {
  return async (dispatch) => {
    try {
      // const res = await fetch(
      //   artifact.visibility === 'private'
      //     ? `${Properties.API_HOST}u/${currentUser.uid}/s/${userProfile.authToken}/packages/${artifact.name}/${artifact.version}/${artifact.jsonInfo.artifactJsonFileName}`
      //     : `${Properties.API_HOST}packages/${artifact.name}/${artifact.version}/${artifact.jsonInfo.artifactJsonFileName}`
      // );
      // const json = await res.json();
      // dispatch(setArtifactJsonFileContent(json));
      // return json;
      const isPrivate = artifact.visibility === 'private' ?
      `${Properties.API_HOST}u/${currentUser.uid}/s/${userProfile.authToken}/packages/${artifact.name}/${artifact.version}/${artifact.jsonInfo.artifactJsonFileName}` :
      `${Properties.API_HOST}packages/${artifact.name}/${artifact.version}/${artifact.jsonInfo.artifactJsonFileName}`;
      const res = await client.get(isPrivate);
      dispatch(setArtifactJsonFileContent(res.data));
      return res.data;
    } catch (error) {
      return error;
    }
  };
};

export const fetchMyPluginJson = (link) => {
  return async (dispatch) => {
    try {
      // dispatch(beginFetchDetails());
      // const res = await fetch(link);
      // const json = await res.json();
      // dispatch(onFetchJSONSuccess(json));
      // return json;
      const res = await client.get(link);
      dispatch(onFetchJSONSuccess(res.data));
      return res.data;
    } catch (error) {
      dispatch(onFetchDetailsFail(error));
    }
  };
};

export const setDetailsArtifact = (
  artifact,
  selectedIndex,
  widgetIndex,
  widgetName
) => ({
  type: SET_DETAILS_ARTIFACT,
  payload: {
    artifact,
    selectedIndex,
    widgetIndex,
    widgetName,
  },
});

export const setWidgetName = (widgetName) => ({
  type: SET_WIDGET_NAME,
  payload: {
    widgetName,
  },
});

export const resetDetailsData = () => ({
  type: RESET_DETAILS_DATA,
});

export const setSelectedArtifactIndex = (selectedIndex) => ({
  type: SET_DETAILS_SELECTED_INDEX,
  payload: {
    selectedIndex,
  },
});

export const setArtifactTab = (tabName) => ({
  type: SET_DETAILS_ARTIFACT_TAB,
  payload: {
    tabName,
  },
});

export const setArtifactSubTab = (subTabName) => ({
  type: SET_DETAILS_ARTIFACT_SUB_TAB,
  payload: {
    subTabName,
  },
});

export const setArtifactJsonFileContent = (jsonContent) => ({
  type: SET_DETAILS_ARTIFACT_JSON_FILE_CONTENT,
  payload: {
    jsonContent,
  },
});

export const setArtifactDocJson = (documentData, selectedDocIndex) => ({
  type: SET_DETAILS_ARTIFACT_DOC,
  payload: {
    documentData,
    selectedDocIndex,
  },
});

export const setWidgetDoc = (documentData) => ({
  type: SET_DETAILS_WIDGET_DOC,
  payload: {
    documentData,
  },
});

export const setWidgetIndex = (widgetIndex) => ({
  type: SET_DETAILS_WIDGET_INDEX,
  payload: {
    widgetIndex,
  },
});

export const clearDetails = () => ({
  type: CLEAR_DETAILS,
});

export const fetchVersionsBegin = () => ({});
