import React, { Component } from 'react';
import { connect } from 'react-redux';
import { myPlugins, oss } from '../../actions';

class DeletePlugin extends Component {
  render() {
    return (
      <div className="popup">
        <div className="inerPopup text-center" style={{ padding: '50px' }}>
          <p style={{ fontWeight: 'bold' }}>
            Are you sure you want to delete this plugin?
          </p>
          <div
            className="row"
            style={{ justifyContent: 'center', marginTop: '20px' }}
          >
            <button
              className="btn btn-primary"
              onClick={async () => {
                this.props.DeletePluginPopupHandler(this.props.pluginToEdit);
                // await this.props.deletePlugin(
                //   this.props.currentUser.uid,
                //   this.props.pluginToEdit.uniqueId,
                //   this.props.currentUser.accessToken
                // );
                await this.props.deletePlugin(this.props.pluginToEdit.uniqueId);
                await this.props.fetchMyPlugins();
                // await this.props.fetchMyPlugins(this.props.currentUser.uid);
                // await this.props.fetchOSS(
                //   this.props.currentUser.uid,
                //   this.props.authToken
                // );
                // await this.props.fetchPagination(
                //   this.props.currentUser.uid,
                //   this.props.authToken
                // );
                await this.props.paginateOss(1, 20, '', '', 'myPlugins');
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-light"
              onClick={() => {
                this.props.DeletePluginPopupHandler(null);
              }}
            >
              No
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  activeComponent: state.myPlugins.activeComponent,
  pluginToEdit: state.myPlugins.pluginToEdit,
});

const mapActionsToProps = {
  DeletePluginPopupHandler: myPlugins.DeletePluginPopupHandler,
  deletePlugin: myPlugins.deletePlugin,
  fetchMyPlugins: myPlugins.fetchMyPlugins,
  // fetchOSS: oss.fetchOSS,
  // fetchPagination: oss.fetchPagination,
  paginateOss: oss.paginateOss,
};
export default connect(mapStateToProps, mapActionsToProps)(DeletePlugin);
