import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveTabPipeline } from '../../actions/activeState';
import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Tabs,
  Tab,
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material';
import {
  LoadingSpinner,
  MyPlugins,
  Search,
  NavigationTabs,
  AllPlugins,
} from '../../components';
import { orderBy } from 'lodash';
import debounce from 'lodash.debounce';

import {
  extractArtifactJsonInfo,
  stripPluginSuffixPrefix,
} from '../../helpers';
import { setDetailsArtifact } from '../../actions/details';
import { fetchTypes, setOssFilters } from '../../actions/oss';
import { Constants } from '../../config';

const CDAP_VERSIONS = [
  '',
  '6.10.0',
  '6.9.2',
  '6.9.1',
  '6.9.0',
  '6.8.3',
  '6.8.2',
  '6.8.1',
  '6.8.0',
  '6.7.3',
  '6.7.2',
  '6.7.1',
  '6.7.0',
  '6.6.0',
  '6.5.1',
  '6.5.0',
  '6.4.1',
  '6.3.0',
  '6.2.3',
  '6.1.4',
];

const OssPlugins = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const oss = useSelector((state) => state.oss);
  const user = useSelector((state) => state.user);
  const myPlugins = useSelector((state) => state.myPlugins);
  const activeState = useSelector((state) => state.activeState);

  const [keyword, setKeyword] = useState('');
  const [cdapVersion, setCdapVersion] = useState(CDAP_VERSIONS[0]);
  const [artifactType, setArtifactType] = useState('');
  const [listView, setListView] = useState(false);

  const pipelineNavHandler = (value) => {
    dispatch(setActiveTabPipeline(value));
    navigate(value === 'pipelines' ? '/pipelines' : '/');
  };

  const filterByBundle = () => {
    const newFilters = {
      ...oss.filters,
      showBundles: !oss.filters.showBundles,
    };
    dispatch(setOssFilters(newFilters));
  };

  const goToDetails = (selectedArtifact, selectedWidget) => {
    const allArtifactVersions = orderBy(
      oss.data.filter((artifact) => artifact.name === selectedArtifact.name),
      'version',
      'desc'
    ).map((artifactVersion) => ({
      ...artifactVersion,
      activeTab: 'plugins',
      activeSubTab: 'cli',
      jsonFileContent: {
        parents: [],
      },
      jsonInfo: extractArtifactJsonInfo(artifactVersion),
      hasDocumentation:
        artifactVersion.docs_file &&
        artifactVersion.docs_file.length === 1 &&
        artifactVersion.docs_file[0].link.startsWith('http'),
    }));
    const artifactIndex = allArtifactVersions.findIndex(
      (artifact) => artifact.version === selectedArtifact.version
    );
    const widgetName =
      selectedWidget && selectedWidget.name ? selectedWidget.name : null;
    const widgetIndex =
      selectedWidget !== null
        ? selectedArtifact.widgets.findIndex(
            (widget) => widget.name === selectedWidget.name
          )
        : -1;
    const widgetStrippedName =
      widgetIndex !== -1
        ? stripPluginSuffixPrefix(selectedWidget.name).toLowerCase()
        : null;
    dispatch(
      setDetailsArtifact(
        allArtifactVersions,
        artifactIndex,
        widgetIndex,
        widgetName
      )
    );
    navigate(
      widgetIndex === -1
        ? `/details/${selectedArtifact.name}/${selectedArtifact.version}/`
        : `/details/${selectedArtifact.name}/${selectedArtifact.version}/${widgetStrippedName}/`
    );
  };

  // eslint-disable-next-line
  const handleSearch = React.useCallback(
    debounce((_keyword) => {
      setKeyword(_keyword);
    }, 500),
    []
  );

  React.useEffect(() => {
    dispatch(fetchTypes());
  }, [dispatch]);

  return (
    <div className="container py-4 row mx-auto">
      <div className="col-xl-12 col-lg-12">
        <div className="card-stats mb-4 mb-xl-0 pd-10">
          <div className="card-body bg-white rounder-borders card-body-shadow">
            {user.currentUser &&
              user.currentPlan === Constants.SUBSCRIPTION_PLANS.ENTERPRISE && (
                <div
                  className="col-lg-12 custom-tabs"
                  style={{ background: '#f8f9fe' }}
                >
                  <Paper
                    className="paper-nav-tabs col-lg-4"
                    style={{ background: '#f8f9fe', border: 'none' }}
                  >
                    <Tabs
                      variant="fullWidth"
                      value={activeState.activeTabPipeline}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={(_, value) => pipelineNavHandler(value)}
                      aria-label="tabs example"
                    >
                      <Tab label="Plugins" value="plugins" />
                      <Tab label="Pipelines" value="pipelines" />
                    </Tabs>
                  </Paper>
                </div>
              )}
            <div className="row" style={{ marginTop: '15px' }}>
              <NavigationTabs />
              <Search handleSearch={handleSearch} />
            </div>
            {myPlugins.activeComponent === 'myPlugins' && (
              <div className="row filter-row">
                <i
                  className="fa fa-filter"
                  style={{
                    color: 'gray',
                    padding: '10px',
                    fontSize: '25px',
                  }}
                />
                <div className="col">
                  <TextField
                    variant="outlined"
                    select
                    label="CDAP Version:"
                    color="primary"
                    fullWidth
                    value={cdapVersion}
                    onChange={(event) => setCdapVersion(event.target.value)}
                  >
                    {CDAP_VERSIONS.map((element) => (
                      <MenuItem key={element} value={element}>
                        {element === '' ? 'All versions' : element}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="col">
                  <TextField
                    variant="outlined"
                    select
                    label="Type:"
                    color="primary"
                    fullWidth
                    value={artifactType}
                    onChange={(event) => setArtifactType(event.target.value)}
                  >
                    {oss.types.map((element, index) => (
                      <MenuItem key={index} value={element}>
                        {element === '' ? 'All types' : element}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className="col filter-bundle pd-top-12">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={oss.filters.showBundles}
                          onChange={() => {
                            filterByBundle();
                          }}
                        />
                      }
                      label="Show Plugin Bundles"
                    />
                  </FormGroup>
                </div>
                {window.innerWidth > 991 && (
                  <label
                    className="text-right fa-list-card"
                    onClick={() => setListView(!listView)}
                    style={{ cursor: 'pointer' }}
                  >
                    <i
                      className={`fa ${!listView ? 'fa-list' : 'fa-th'} button-click`}
                      style={{ color: 'gray', fontSize: '21px' }}
                    />
                  </label>
                )}
              </div>
            )}
            <div className="card-list-wrapper">
              {['all', 'openSource', 'commercial'].includes(oss.activeTab) && (
                <AllPlugins
                  keyword={keyword}
                  cdapVersion={cdapVersion}
                  listView={listView}
                  goToDetails={goToDetails}
                  activeTab={oss.activeTab}
                  type={artifactType}
                />
              )}
              {oss.paginationIsLoading && (
                <LoadingSpinner textToShow="Loading..." />
              )}
              {oss.activeTab === 'myPlugins' && (
                <MyPlugins goToDetails={goToDetails} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OssPlugins;
