import React from 'react';
import { LoadingSpinner } from '../../components';
import { userActions } from '../../actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  React.useEffect(() => {
    dispatch(userActions.signOut(() => navigate('/')));
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <LoadingSpinner />
    </div>
  );
};

export default Logout;
