import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PaymentFailed, LoadingSpinner } from '../';
import CheckIcon from '../../assets/images/checkIcon.png';

class SubscriptionReceipt extends Component {
  convertDate = (unixTimestamp) => {
    const dateObj = new Date(unixTimestamp * 1000);
    return dateObj.toUTCString();
  };

  render() {
    const subscriptionRes = this.props.subscriptionRes?.data;
    const { error, isLoading } = this.props;
    if (isLoading) {
      return <LoadingSpinner textToShow="Loading..." />;
    }
    if (error !== null) {
      return (
        <div>
          <PaymentFailed history={this.props.history} />
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-lg-3">
          <img alt="Placeholder" src={CheckIcon} />
        </div>
        <div className="col-lg-7 payment">
          <div className="card-stats mb-4 mb-xl-0 pd-10">
            <div className="card-body bg-color-shoppingList rounder-borders">
              <div className="col-lg-12">
                <h2 className="text-center">Success</h2>
                <h3 className="text-center">Thank you for subscribing!</h3>
                <br />
                <br />
                <h4>Email: {subscriptionRes?.email}</h4>
                <h4>
                  Starting date:{' '}
                  {this.convertDate(
                    subscriptionRes?.current_subscription?.created
                  )}
                </h4>
                <h4>
                  Expiration date:{' '}
                  {this.convertDate(
                    subscriptionRes?.current_subscription?.current_period_end
                  )}
                </h4>
                <h4>Status: {subscriptionRes?.current_subscription?.object}</h4>
                <h4>
                  Amount: $
                  {subscriptionRes?.current_subscription?.plan?.amount
                    ? subscriptionRes?.current_subscription?.plan?.amount / 100
                    : 'No amount available'}
                </h4>
                <br />
                <div className="text-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      this.props.history.push('/');
                    }}
                  >
                    Back
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      window.print();
                    }}
                  >
                    Print
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  price: state.shoppingCart.price,
  subscriptionRes: state.shoppingCart.subscriptionRes,
  error: state.shoppingCart.error,
  isLoading: state.shoppingCart.isLoading,
  ...ownProps,
});

export default connect(mapStateToProps)(SubscriptionReceipt);
