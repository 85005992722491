import React, { Component } from 'react';
import { connect } from 'react-redux';
import { paymentMethods } from '../../actions';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { AddPayment, LoadingSpinner, PaymentCardItem } from '../';
import { Properties } from '../../config';

class PaymentMethodsList extends Component {
  state = {
    showAddPayment: false,
  };

  componentDidMount() {
    console.debug('STRIPE_API_KEY', Properties.STRIPE_API_KEY, Properties);
    if (this.props.userLoggedIn) {
      const { uid } = this.props.currentUser;
      this.props.fetchUserPaymentMethod(
        uid,
        this.props.currentUser.accessToken
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.userLoggedIn !== this.props.userLoggedIn &&
      nextProps.userLoggedIn === true
    ) {
      this.props.fetchUserPaymentMethod(
        nextProps.currentUser.uid,
        nextProps.currentUser.accessToken
      );
    }
  }

  render() {
    console.debug('STRIPE_API_KEY', Properties.STRIPE_API_KEY, Properties);
    const items = this.props.paymentMethodsData.map((viewItem, index) => {
      return <PaymentCardItem key={index} data={viewItem} />;
    });
    if (this.props.isLoading) {
      return <LoadingSpinner textToShow="Loading..." />;
    }
    return (
      <div className="justify-content-center">
        <div className="card-stats mb-4 mb-xl-0 pd-10">
          <div className="card-body ">
            <div className="row" style={{ padding: '0px 35px' }}>
              <div className="col-lg-6">
                <h3 style={{ paddingTop: '12px' }}>{`Select payment card`}</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card-body">
                  <div className="col-lg-12">
                    <div className="col-lg-12 mgTopBottom border-top pd-10">
                      <div className="row">{items}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 text-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.setState({
                    showAddPayment: !this.state.showAddPayment,
                  });
                }}
              >
                Add new credit card
              </button>
            </div>
          </div>
        </div>
        {this.state.showAddPayment && (
          <StripeProvider apiKey={Properties.STRIPE_API_KEY}>
            <Elements>
              <AddPayment />
            </Elements>
          </StripeProvider>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  currentPlan: state.user.currentPlan,
  paymentMethodsData: state.paymentMethods.data,
  isLoading: state.paymentMethods.isLoading,
  // ...ownProps,
});

const mapActionsToProps = {
  fetchUserPaymentMethod: paymentMethods.fetchUserPaymentMethod,
};

export default connect(mapStateToProps, mapActionsToProps)(PaymentMethodsList);
