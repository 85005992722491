import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pipelines } from '../../../actions';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch from '@mui/material/Switch';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: '10px',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

class PipelineTable extends Component {
  state = {
    checked: false,
    order: 'asc',
    orderBy: 'name',
    page: 0,
    // dense: false,
    rowsPerPage: 5,
    keyword: '',
  };

  descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  };

  stableSort = (pipelines, comparator) => {
    const stabilizedThis = pipelines.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  handleSearch = async (event) => {
    await this.setState({
      keyword: event.target.value,
    });
    await this.props.filterPipelines(
      this.state.keyword,
      this.props.instanceIndex
    );
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    this.setState({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    });
  };

  handleSelectAllClick = async (event) => {
    if (event.target.checked) {
      const newSelecteds = await this.props.instanceData.filteredData.map(
        (n) => n.name
      );
      await this.props.setCheckedPipelines(
        newSelecteds,
        this.props.instanceIndex
      );
      return;
    }
    this.props.setCheckedPipelines([], this.props.instanceIndex);
  };

  setPipeline = (event, name) => {
    const { checkedPipelines } = this.props.instanceData;
    const selectedIndex = checkedPipelines.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(checkedPipelines, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(checkedPipelines.slice(1));
    } else if (selectedIndex === checkedPipelines.length - 1) {
      newSelected = newSelected.concat(checkedPipelines.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        checkedPipelines.slice(0, selectedIndex),
        checkedPipelines.slice(selectedIndex + 1)
      );
    }
    this.props.setCheckedPipelines(newSelected, this.props.instanceIndex);
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  handleChangeDense = (event) => {
    this.props.densePadding();
    // this.setState({ dense: event.target.checked });
  };

  isSelected = (name) =>
    this.props.instanceData.checkedPipelines.indexOf(name) !== -1;

  emptyRows =
    this.state.rowsPerPage -
    Math.min(
      this.state.rowsPerPage,
      this.props.instanceData.filteredData.length -
        this.state.page * this.state.rowsPerPage
    );

  render() {
    const { classes } = this.props;
    const { order, orderBy, page, rowsPerPage } = this.state;
    const { dense } = this.props;
    const { instanceData } = this.props;
    const pipelines = this.props.instanceData.filteredData;
    return (
      <div className={classes.root}>
        {/* <Paper className={classes.paper}> */}
        <EnhancedTableToolbar
          numSelected={instanceData.checkedPipelines.length}
          handleSearch={this.handleSearch.bind(this)}
          instanceData={instanceData}
          instanceIndex={this.props.instanceIndex}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={instanceData.checkedPipelines.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick.bind(this)}
              onRequestSort={this.handleRequestSort.bind(this)}
              rowCount={pipelines.length}
              instanceData={instanceData}
            />
            <TableBody>
              {this.stableSort(pipelines, this.getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((pipelines, index) => {
                  const isItemSelected = this.isSelected(pipelines.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event) =>
                        this.setPipeline(event, pipelines.name)
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={pipelines.name}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell align="left">{pipelines.name}</TableCell>
                      <TableCell align="left">
                        {pipelines.description}
                      </TableCell>
                      <TableCell align="left">{pipelines.status}</TableCell>
                    </TableRow>
                  );
                })}
              {/* {this.emptyRows > 0 && (
                    <TableRow
                      style={{ height: (dense ? 33 : 53) * this.emptyRows }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={pipelines.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={this.handleChangePage.bind(this)}
          onRowsPerPageChange={this.handleChangeRowsPerPage.bind(this)}
        />
        {/* </Paper> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  pipelineData: state.pipelines.data,
  checkedPipelines: state.pipelines.checkedPipelines,
  isLoading: state.pipelines.isLoading,
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  activeComponent: state.myPlugins.activeComponent,
  dense: state.pipelines.densePadding,
});

const mapActionsToProps = {
  setCheckedPipelines: pipelines.setCheckedPipelines,
  filterPipelines: pipelines.filterPipelines,
  // densePadding: pipelines.densePadding,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(PipelineTable));
