import {
  OSS_SEARCH,
  SET_OSS_FILTERS,
  UPDATE_PURCHASE_STATUS,
  SET_ITEMS_COUNT,
  BEGIN_TYPES_FETCH,
  TYPES_FETCH_SUCCESS,
  TYPES_FETCH_FAIL,
  BEGIN_PAGINATION,
  PAGINATION_SUCCESS,
  PAGINATION_FAIL,
} from './types';
import { client } from '../config/api';

import { Properties } from '../config';

export const setItemsCount = (itemsNr) => ({
  type: SET_ITEMS_COUNT,
  payload: { itemsNr },
});

export const Search = (keyword) => ({
  type: OSS_SEARCH,
  payload: { keyword },
});

export const setOssFilters = (filters) => ({
  type: SET_OSS_FILTERS,
  payload: {
    filters,
  },
});

export const updatePurchaseStatus = (purchasedArtifacts) => ({
  type: UPDATE_PURCHASE_STATUS,
  payload: {
    purchasedArtifacts,
  },
});

const beginFetchTypes = () => ({
  type: BEGIN_TYPES_FETCH,
});

const fetchTypesSuccess = (data) => ({
  type: TYPES_FETCH_SUCCESS,
  payload: { data },
});

const fetchTypesFail = () => ({
  type: TYPES_FETCH_FAIL,
});

export const fetchTypes = () => {
  return async (dispatch) => {
    try {
      dispatch(beginFetchTypes());
      const res = await client.get('packages_temp/types');
      return dispatch(fetchTypesSuccess(res.data));
    } catch (error) {
      return dispatch(fetchTypesFail());
    }
  };
};

const beginPagination = () => ({
  type: BEGIN_PAGINATION,
});

const paginationSuccess = (data) => ({
  type: PAGINATION_SUCCESS,
  payload: data,
});

const paginationFail = () => ({
  type: PAGINATION_FAIL,
});

export const paginateOss = (
  page,
  perPage = 20,
  keyword = '',
  cdapVersion = '',
  activeTab = 'all',
  type = ''
) => {
  return async (dispatch) => {
    try {
      dispatch(beginPagination());
      let url = `${Properties.API_HOST}packages_temp/?page=${page}&per_page=${perPage}&active_tab=${activeTab}`;
      if (keyword !== '') {
        url = `${url}&keyword=${keyword}`;
      }
      if (cdapVersion !== '') {
        url = `${url}&cdap_version=${cdapVersion}`;
      }
      if (type !== '') {
        url = `${url}&plugin_type=${type}`;
      }
      // const res = await fetch(url);
      // const json = await res.json();
      // return dispatch(paginationSuccess({ ...json, page }));
      const res = await client.get(url);
      return dispatch(paginationSuccess({ ...res.data, page }));
    } catch (error) {
      dispatch(paginationFail());
    }
  };
};
