import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import assets from '../../assets';
import { connect } from 'react-redux';
import { activeState, shoppingCart, userActions } from '../../actions';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Tooltip from '@mui/material/Tooltip';
import { withRouter } from '../../hooks';
import Logo from '../../assets/images/logo_black_adaptiveescale.png';

class Navbar extends Component {
  state = {
    open: false,
  };

  handleClose = () => {
    this.setState({ open: !this.state.open });
  };

  logOutAndReload = async () => {
    // this.props.signOut(() => {
    //   // window.location.replace('/login');
    // });
    // this.props.clearShoppingCart();
    // this.props.setActiveTab('all');
    this.props.history.push('/logout');
  };

  signOut = () => {
    return (
      <div className="mg-left-17">
        <span className="avatar avatar-sm rounded-circle">
          <img
            src={this.props.currentUser?.picture ?? assets.images.defaultUser}
            referrerPolicy="no-referrer"
            onClick={() => {
              this.handleClose();
            }}
            key={this.props.currentUser?.picture}
            style={{ cursor: 'pointer' }}
            alt="User Profile"
          />
        </span>
        <Popper
          style={{ top: 'none', left: 'none', marginLeft: '-80px', zIndex: 1 }}
          open={this.state.open}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={() => {
                    this.setState({ open: !this.state.open });
                  }}
                >
                  <MenuList autoFocusItem={this.state.open} id="menu-list-grow">
                    <MenuItem
                      onClick={() => {
                        this.props.history.push('/userProfile');
                        this.setState({ open: !this.state.open });
                      }}
                    >
                      User Profile
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        this.props.history.push('/pricing-plans');
                        this.setState({ open: !this.state.open });
                      }}
                    >
                      Pricing
                    </MenuItem>
                    <MenuItem
                      onClick={async () => {
                        await this.logOutAndReload();
                        this.setState({ open: !this.state.open });
                      }}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  };

  signIn = () => {
    return (
      <div className="media align-items-center">
        <div className="media-body ml-2 d-none d-lg-block">
          <Link
            to="/login"
            title="SignIn"
            className="mb-0 text-sm text-black font-weight-bold mx-3"
          >
            Login
          </Link>
        </div>
      </div>
    );
  };

  render() {
    const itemCount = this.props.shoppingCartData.length;
    return (
      <nav
        style={{
          boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
          backgroundColor: '#f8f9fe',
          padding: '0 20px',
        }}
      >
        <div
          className="container"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px 24px',
          }}
        >
          <div
            style={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
            }}
            className="scale-on-hover"
          >
            <img
              className="logo-adaptivescale"
              src={Logo}
              alt=""
              onClick={() => {
                this.props.history.push('/');
                this.props.setActiveTabPipeline('plugins');
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {this.props.userLoggedIn && (
              <>
                <div>
                  <Tooltip title="Shopping Cart">
                    <label
                      style={{ cursor: 'pointer' }}
                      // title="ShoppingCartList"
                      className="mb-0 text-sm text-black font-weight-bold"
                      onClick={() => this.props.history.push('/shoppingCart')}
                    >
                      <i
                        className="fa fa-shopping-cart fa-2x"
                        style={{ marginRight: '5px' }}
                      />
                    </label>
                  </Tooltip>
                </div>
                <div>
                  <button className="btn-shoppingCart-nr">
                    <p style={{ color: 'white', fontSize: '13px' }}>
                      {itemCount}
                    </p>
                  </button>
                </div>
              </>
            )}
            <div className="nav-item dropdown">
              {this.props.userLoggedIn ? this.signOut() : this.signIn()}
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  userLoggedIn: PropTypes.bool,
  currentUser: PropTypes.object,
  shoppingCartData: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  shoppingCartData: state.shoppingCart.data,
});

const mapActionToProps = {
  setActiveTab: activeState.setActiveTab,
  clearShoppingCart: shoppingCart.clearShoppingCart,
  setActiveTabPipeline: activeState.setActiveTabPipeline,
  signOut: userActions.signOut,
};

export default withRouter(connect(mapStateToProps, mapActionToProps)(Navbar));
