import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from '../../hooks';
import { connect } from 'react-redux';

class DetailsHeader extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="row" style={{ marginTop: '20px' }}>
        <div className="col-lg-1 text-right">
          <button className="btn-no-bg">
            <i
              className="fa fa-arrow-left button-click"
              onClick={() => this.props.history.push('/')}
              style={{ color: 'gray', cursor: 'pointer' }}
            />
          </button>
        </div>
        <div className="col-lg-5">
          <h4>Plugin Detail</h4>
        </div>
        <div>
          <div className="price-text">
            {data[0] && data[0].commercial === true ? `$${data[0].price}` : ''}
          </div>
        </div>
      </div>
    );
  }
}

DetailsHeader.propTypes = {
  authToken: PropTypes.string,
  isUserSet: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  ...ownProps,
});

export default withRouter(connect(mapStateToProps)(DetailsHeader));
