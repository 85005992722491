import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

class Search extends Component {
  state = {
    keyword: '',
  };

  changeHandler = (event) => {
    const keyword = event.target.value;
    this.setState({
      ...this.state,
      keyword: keyword,
    });
    this.props.handleSearch(keyword);
  };

  render() {
    return (
      <div className="col" style={{ paddingTop: '10px' }}>
        <form>
          <Grid container spacing={1} style={{ flexDirection: 'row-reverse' }}>
            <TextField
              id="outlined"
              label={
                <div>
                  <i className="fa fa-search" style={{ color: 'gray' }}></i>{' '}
                  Search
                </div>
              }
              color="primary"
              variant="outlined"
              fullWidth
              value={this.state.keyword}
              onChange={this.changeHandler}
            />
          </Grid>
        </form>
      </div>
    );
  }
}

export default Search;
