import React, { Component } from 'react';
import { connect } from 'react-redux';
import { paymentMethods } from '../../actions';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { set as setIn } from 'lodash';
import countryList from 'react-select-country-list';

class AddPayment extends Component {
  state = {
    name: '',
    lastName: '',
    address_line1: '',
    address_city: '',
    address_state: '',
    address_zip: '',
    errorMessage: '',
    proccessing: false,
    statesOptions: countryList().getData(),
  };

  errorHandler = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  changeHandler = (event) => {
    const newState = setIn(this.state, event.target.name, event.target.value);
    this.setState({
      ...newState,
    });
  };

  handleSubmit = async (evt) => {
    if (!this.addPaymentForm.checkValidity()) {
      this.addPaymentForm.reportValidity();
      return;
    }
    evt.preventDefault();
    if (this.props.stripe) {
      let token = await this.props.stripe.createToken({
        name: this.state.name,
        // lastName: this.state.lastName,
        email: this.props.currentUser.email,
        address_line1: this.state.address,
        address_city: this.state.address_city,
        address_state: this.state.address_state,
        address_zip: this.state.address_zip,
      });
      await this.props.attachPaymentMethod(
        this.props.currentUser.uid,
        this.props.currentUser.accessToken,
        token
      );
    }
  };
  render() {
    return (
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-2" />
          <div className="col-lg-9 payment">
            <div className="card-stats mb-4 mb-xl-0 pd-10">
              <div className="card-body bg-white rounder-borders card-body-shadow">
                <div className="col-lg-12">
                  <form
                    name="addPaymentForm"
                    ref={(form) => (this.addPaymentForm = form)}
                  >
                    <div className="form-group row">
                      <div className="col-sm-6">
                        First Name
                        <input
                          name="name"
                          className="form-control new-input-sizes"
                          onChange={(event) => {
                            this.changeHandler(event);
                          }}
                          required
                          style={{ padding: '17px' }}
                        />
                      </div>
                      <div className="col-sm-6">
                        Last Name
                        <input
                          name="lastName"
                          className="form-control new-input-sizes"
                          required
                          onChange={(event) => {
                            this.changeHandler(event);
                          }}
                          style={{ padding: '17px' }}
                        />
                      </div>
                      <div className="col-sm-12">
                        Card details
                        <CardElement onChange={this.errorHandler} />
                      </div>
                      <div className="col-sm-12">
                        Address
                        <input
                          name="address_line1"
                          className="form-control new-input-sizes"
                          required
                          onChange={(event) => {
                            this.changeHandler(event);
                          }}
                          style={{ padding: '17px', minWidth: '0px' }}
                        />
                      </div>
                      <div className="col-sm-4">
                        City
                        <input
                          name="address_city"
                          className="form-control new-input-sizes"
                          required
                          onChange={(event) => {
                            this.changeHandler(event);
                          }}
                          style={{ padding: '17px', minWidth: '0px' }}
                        />
                      </div>
                      <div className="col-sm-4">
                        State
                        <select
                          name="address_state"
                          required
                          onChange={(value) => {
                            this.changeHandler(value);
                          }}
                          className="dropdown-states form-control"
                          style={{
                            height: 'calc(2.10rem + 2px)',
                            fontSize: '15px',
                          }}
                          value={this.state.address_state}
                        >
                          <option value="">Select State</option>
                          {this.state.statesOptions.map((element, index) => (
                            <option key={index} value={element.label}>
                              {element.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-sm-4">
                        ZIP
                        <input
                          name="address_zip"
                          className="form-control new-input-sizes"
                          required
                          onChange={(event) => {
                            this.changeHandler(event);
                          }}
                          style={{ padding: '17px', minWidth: '0px' }}
                        />
                      </div>
                    </div>

                    <div className="error" role="alert">
                      {this.state.errorMessage}
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary btn-block"
                      onClick={(event) => this.handleSubmit(event)}
                    >
                      Add
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userLoggedIn: state.user.userLoggedIn,
  currentUser: state.user.currentUser,
  authToken: state.user.authToken,
  isUserSet: state.user.isUserSet,
  currentPlan: state.user.currentPlan,
  paymentMethodsData: state.paymentMethods.data,
  isLoading: state.paymentMethods.isLoading,
  // ...ownProps,
});

const mapActionsToProps = {
  attachPaymentMethod: paymentMethods.attachPaymentMethod,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(injectStripe(AddPayment));
